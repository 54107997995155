import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './TrackingPage.scss';
import api from '../Axios/axios';
import { FaPencilAlt, FaArrowRight } from 'react-icons/fa';

const TrackingPage = () => {
  const navigate = useNavigate();
  const [towerCranes, setTowerCranes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [filters, setFilters] = useState({
    serialNumber: '',
    owner: '',
    brand: '',
    model: '',
    height: '',
    tonnage: '',
    status: 'all',
    rentalCompany: '',
    workSite: '',
  });
  const formatCurrency = (amount) => {
    // Değer sayısal değilse sayıya dönüştür
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
    // Geçersiz veya boş bir değer için kontrol
    if (isNaN(numericAmount) || numericAmount === null || numericAmount === undefined) {
      return '-';
    }
  
    // Geçerli bir sayı için parasal format uygulama
    return new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numericAmount) + ' TL';
  };
  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const ownerId = decodedToken.CompanyId;
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    setOwnerId(ownerId);
    setUserRole(role);
  };

  const fetchAllRentals = async () => {
    try {
      const rentalResponse = await api.get('/Rental');
      const allRentals = rentalResponse.data.data;
      setRentals(allRentals);
    } catch (error) {
      console.error('Kiralama bilgileri yüklenemedi:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const craneResponse = await api.get('/TowerCrane');
        setTowerCranes(craneResponse.data.data);

        const companyResponse = await api.get('/Company');
        setCompanies(companyResponse.data.data);

        const brandResponse = await api.get('/Brand');
        setBrands(brandResponse.data.data);

        const warehouseResponse = await api.get('/Warehouse');
        setWarehouses(warehouseResponse.data.data);

        await fetchAllRentals();
      } catch (error) {
        console.error('Veriler yüklenemedi:', error);
      }
    };

    getUserFromToken();
    fetchData();
  }, [ownerId]);

  const handleFilterChange = (e, filterName) => {
    setFilters({
      ...filters,
      [filterName]: e.target.value,
    });
  };
  const isNearEndDate = (endDate) => {
    if (!endDate) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Gün başlangıcına ayarla
    const timeDiff = endDate - today; // Tarih farkı
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24); // Gün cinsinden fark
  
    return daysDiff <= 30 && daysDiff >= 0; // Bitiş tarihi 0 ile 30 gün arasındaysa true
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR');
  };

  const getCompanyName = (ownerId) => {
    const company = companies.find((company) => company.id === ownerId);
    return company ? company.name : '-';
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((brand) => brand.id === brandId);
    return brand ? brand.name : '-';
  };

  const getRentalDetails = (crane) => {
    // Sadece isActive olan kiralamayı bul
    const activeRental = rentals.find((rental) => rental.towerCraneId === crane.id && rental.isActive);

    if (activeRental) {
      return {
        startDate: formatDate(activeRental.rentalStartDate),
        endDate: formatDate(activeRental.rentalEndDate),
        fee: activeRental.rent ? `${activeRental.rent} TL` : '-',
        rentalDuration: activeRental.rentalPeriod + activeRental.extraPeriod  || '-',
        workSite: activeRental.workSite || '-',
        status: 'Inused',
        rentalCompany: activeRental.rentingCompany || '-',
        rentalId: activeRental.id,
      };
    }

    // Eğer aktif kiralama yoksa
    return {
      startDate: '-',
      endDate: '-',
      fee: '-',
      rentalDuration: '-',
      workSite: crane.warehouse || '-',
      status: 'Active',
      rentalCompany: '-',
      rentalId: null,
    };
  };


  const filteredTowerCranes = towerCranes
    .filter((crane) => {
      if (userRole === 'Company' && crane.ownerId !== ownerId) return false;

      const rentalDetails = getRentalDetails(crane);
      //  console.log(crane);
      const matchesSerialNumber = !filters.serialNumber || crane.serialNo.toLowerCase().includes(filters.serialNumber.toLowerCase());
      const matchesOwner = !filters.owner || crane.ownerId === filters.owner;
      const matchesBrand = !filters.brand || crane.brandId.toString() === filters.brand;
      const matchesModel = !filters.model || crane.model === filters.model;
      const matchesHeight = !filters.height || crane.height.toString() === filters.height;
      const matchesTonnage = !filters.tonnage || crane.maxTonnage.toString() === filters.tonnage;
      const matchesStatus = filters.status === 'all' || rentalDetails.status === filters.status;
      const matchesRentalCompany = !filters.rentalCompany || rentalDetails.rentalCompany === filters.rentalCompany;
      const matchesWorkSite = !filters.workSite || rentalDetails.workSite === filters.workSite;

      return (
        matchesSerialNumber &&
        matchesOwner &&
        matchesBrand &&
        matchesModel &&
        matchesHeight &&
        matchesTonnage &&
        matchesStatus &&
        matchesRentalCompany &&
        matchesWorkSite
      );
    })
    .sort((a, b) => {
      const rentalA = getRentalDetails(a);
      const rentalB = getRentalDetails(b);

      if (rentalA.status === 'Active' && rentalB.status !== 'Active') return -1;
      if (rentalA.status !== 'Active' && rentalB.status === 'Active') return 1;

      const isCloseToEndDateA = isNearEndDate(rentalA.endDate);
      const isCloseToEndDateB = isNearEndDate(rentalB.endDate);
      if (isCloseToEndDateA && !isCloseToEndDateB) return 1;
      if (!isCloseToEndDateA && isCloseToEndDateB) return -1;

      return 0;
    });

  const uniqueBrands = Array.from(new Set(filteredTowerCranes.map((crane) => crane.brandId)));
  const uniqueOwners = Array.from(new Set(filteredTowerCranes.map((crane) => crane.ownerId)));
  const uniqueModels = Array.from(new Set(filteredTowerCranes.map((crane) => crane.model)));
  const uniqueHeights = Array.from(new Set(filteredTowerCranes.map((crane) => crane.height)));
  const uniqueTonnages = Array.from(new Set(filteredTowerCranes.map((crane) => crane.maxTonnage)));
  const uniqueRentalCompanies = Array.from(new Set(filteredTowerCranes.map((crane) => getRentalDetails(crane).rentalCompany)));
  const uniqueWorkSites = Array.from(new Set(filteredTowerCranes.map((crane) => getRentalDetails(crane).workSite)));

  const handleEdit = (rentalId) => {
    navigate(`/rentaledit/${rentalId}`);
  };

  const handleRentalPage = (craneId) => {
    navigate(`/rentalpage/${craneId}`);
  };

  const getStatusClass = (status, isCloseToEndDate) => {
    if (status === 'Inused') {
      return isCloseToEndDate ? 'row-warning' : 'row-used';
    }
    return 'row-active';
  };
  const sortedTowerCranes = filteredTowerCranes.sort((a, b) => {
    const rentalA = getRentalDetails(a);
    const rentalB = getRentalDetails(b);
  
    const isCloseToEndDateA = isNearEndDate(rentalA.endDate);
    const isCloseToEndDateB = isNearEndDate(rentalB.endDate);
  
    if (rentalA.status === 'Active' && rentalB.status !== 'Active') return -1;
    if (rentalA.status !== 'Active' && rentalB.status === 'Active') return 1;
  
    if (rentalA.status === 'Inused' && isCloseToEndDateA && rentalB.status === 'Inused' && !isCloseToEndDateB) return -1;
    if (rentalA.status === 'Inused' && !isCloseToEndDateA && rentalB.status === 'Inused' && isCloseToEndDateB) return 1;
  
    return 0;
  });

  return (
    <div className="tracking-page">
      <Navbar />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Seri Numarası</th>
              <th>Kule Vinç Sahibi</th>
              <th>Marka</th>
              <th>Model</th>
              <th>Yükseklik</th>
              <th>Tonaj</th>
              <th>Durum</th>
              <th>Kira Başlangıç Tarihi</th>
              <th>Kira Bitiş Tarihi</th>
              <th>Ücret</th>
              <th>Kira Süresi (Ay)</th>
              <th>Kiralanan Firma</th>
              <th>Şantiye/Depo</th>
              <th>İşlemler</th>
            </tr>
            <tr>
              {/* Filtreler */}
              <th>
                <div className="input-container">
                  <input
                    type="text"
                    value={filters.serialNumber}
                    placeholder="Seri No Ara"
                    onChange={(e) => handleFilterChange(e, 'serialNumber')}
                  />
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.owner}
                    onChange={(e) => handleFilterChange(e, 'owner')}
                  >
                    <option value="">Tümü</option>
                    {uniqueOwners.map((owner, index) => (
                      <option key={index} value={owner}>
                        {getCompanyName(owner)}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.brand}
                    onChange={(e) => handleFilterChange(e, 'brand')}
                  >
                    <option value="">Tümü</option>
                    {uniqueBrands.map((brand, index) => (
                      <option key={index} value={brand}>
                        {getBrandName(brand)}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.model}
                    onChange={(e) => handleFilterChange(e, 'model')}
                  >
                    <option value="">Tümü</option>
                    {uniqueModels.map((model, index) => (
                      <option key={index} value={model}>
                        {model}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.height}
                    onChange={(e) => handleFilterChange(e, 'height')}
                  >
                    <option value="">Tümü</option>
                    {uniqueHeights.map((height, index) => (
                      <option key={index} value={height}>
                        {height}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.tonnage}
                    onChange={(e) => handleFilterChange(e, 'tonnage')}
                  >
                    <option value="">Tümü</option>
                    {uniqueTonnages.map((tonnage, index) => (
                      <option key={index} value={tonnage}>
                        {tonnage}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.status}
                    onChange={(e) => handleFilterChange(e, 'status')}
                  >
                    <option value="all">Tümü</option>
                    <option value="Active">Boşta</option>
                    <option value="Inused">Kirada</option>
                  </select>
                </div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.rentalCompany}
                    onChange={(e) => handleFilterChange(e, 'rentalCompany')}
                  >
                    <option value="">Tümü</option>
                    {uniqueRentalCompanies.map((company, index) => (
                      <option key={index} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.workSite}
                    onChange={(e) => handleFilterChange(e, 'workSite')}
                  >
                    <option value="">Tümü</option>
                    {uniqueWorkSites.map((site, index) => (
                      <option key={index} value={site}>
                        {site}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            {sortedTowerCranes.map((crane, index) => {
              const rentalDetails = getRentalDetails(crane);
              const isCloseToEndDate = isNearEndDate(rentalDetails.endDate);
              const rowClass = getStatusClass(rentalDetails.status, isCloseToEndDate);

              //  console.log(crane.serialNo, rentalDetails);
              return (
                <tr key={crane.id} className={rowClass}>
                  <td>{crane.serialNo}</td>
                  <td>{getCompanyName(crane.ownerId)}</td>
                  <td>{getBrandName(crane.brandId)}</td>
                  <td>{crane.model || '-'}</td>
                  <td>{crane.height}m</td>
                  <td>{crane.maxTonnage}T</td>
                  <td>  
                  {rentalDetails.status === 'Inused' ? 'Kirada' : 'Boşta'}</td>
                  <td>{rentalDetails.startDate}</td>
                  <td>{rentalDetails.endDate}</td>
                  <td>{formatCurrency(rentalDetails.fee) }</td>
                  <td>{rentalDetails.rentalDuration}</td>
                  <td>{rentalDetails.rentalCompany}</td>
                  <td>{rentalDetails.workSite || '-'}</td>
                  <td>
                    <div className="btn-container">
                      {userRole === 'Owner' && rentalDetails.rentalId && (
                        <button className="small-btn edit-btn" onClick={() => handleEdit(rentalDetails.rentalId)}>
                          <FaPencilAlt />
                        </button>
                      )}
                      <button className="small-btn details-btn" onClick={() => handleRentalPage(crane.id)}>
                        <FaArrowRight />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrackingPage;
