import React, { useState, useEffect } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './CompanyEdit.scss';
import api from '../Axios/axios'; // Axios ile API bağlantısı kuruyoruz

const CompanyEdit = () => {
  const { id } = useParams(); // URL'den şirket ID'sini alıyoruz
  const navigate = useNavigate(); // Başka sayfalara yönlendirme işlemi için

  const [formData, setFormData] = useState({
    id: '', // Şirket ID'si
    hasTowerCrane: false,
    rentTowerCrane: false,
    name: '',
    email: '',
    address: '',
    taxNo: '',
    authorizedPerson1: '',
    phoneNumber1: '',
    authorizedPerson2: '',
    phoneNumber2: '',
  });

  const [loading, setLoading] = useState(true);  // Yüklenme durumu için state
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Şirket bilgilerini /api/Company/GetById API'si ile alıyoruz
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await api.get(`/Company/GetById?id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Yanıt verisini kontrol edin
        console.log('Şirket bilgileri:', response.data);

        // Eğer yanıt verisi varsa, form verilerini set ediyoruz
        if (response.data.data) {
          const companyData = response.data.data;
          setFormData({
            id: companyData.id || '',
            hasTowerCrane: companyData.hasTowerCrane , 
            rentTowerCrane: companyData.rentTowerCrane , 
            name: companyData.name || '',
            email: companyData.email || '',
            address: companyData.address || '',
            taxNo: companyData.taxNo || '',
            authorizedPerson1: companyData.authorizedPerson1 || '',
            phoneNumber1: companyData.phoneNumber1 || '',
            authorizedPerson2: companyData.authorizedPerson2 || '',
            phoneNumber2: companyData.phoneNumber2 || '',
          });
        }

        setLoading(false);
      } catch (error) {
        console.error('Şirket bilgileri yüklenemedi:', error);
        setErrorMessage('Şirket bilgileri yüklenemedi. Lütfen tekrar deneyin.');
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(`${name}: ${type === 'checkbox' ? checked : value}`); // Değerleri kontrol etmek için
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value, // Checkboxlar için true/false değerini al
    });
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
  
      // Gönderilen verileri kontrol etmek için
      console.log('Güncelleme için gönderilen form verileri:', formData);
  
      const response = await api.put('/Company', formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization başlığı ile gönderiyoruz
        },
      });
  
      console.log('Güncelleme yanıtı:', response.data);
      setSuccessMessage('Şirket bilgileri başarıyla güncellendi.');
      setErrorMessage('');
      navigate('/company-list'); // Güncelleme sonrası şirket listesine yönlendirme
    } catch (error) {
      console.error('Şirket bilgileri güncellenemedi:', error);
      setErrorMessage('Şirket bilgileri güncellenemedi. Lütfen tekrar deneyin.');
      setSuccessMessage('');
    }
  };
  

  if (loading) {
    return <p>Yükleniyor...</p>;  // Veriler yüklenirken bir mesaj gösteriyoruz
  }

  return (
    <div className="company-edit-page">
      <Navbar />
      <div className="form-wrapper">
        <h2>Şirket Düzenle</h2>
        <form onSubmit={handleSubmit}>
          {/* Şirket Bilgileri */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Şirket Adı"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Şirket Email"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Şirket Adresi"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="taxNo"
                value={formData.taxNo}
                onChange={handleInputChange}
                placeholder="Vergi No"
                required
              />
            </div>
          </div>

          {/* Checkbox fields */}
          <div className="form-row checkbox-group">
            <div className="form-group">
              <label>Kule Vinci Var mı?</label>
              <input
                type="checkbox"
                name="hasTowerCrane"
                checked={formData.hasTowerCrane} // Ensure this reflects current state
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Kule Vinci Kiralıyor mu?</label>
              <input
                type="checkbox"
                name="rentTowerCrane"
                checked={formData.rentTowerCrane} // Ensure this reflects current state
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Yetkili Kişiler ve İletişim Bilgileri */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="authorizedPerson1"
                value={formData.authorizedPerson1}
                onChange={handleInputChange}
                placeholder="Yetkili Kişi 1"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber1"
                value={formData.phoneNumber1}
                onChange={handleInputChange}
                placeholder="İletişim No 1"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="authorizedPerson2"
                value={formData.authorizedPerson2}
                onChange={handleInputChange}
                placeholder="Yetkili Kişi 2"
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber2"
                value={formData.phoneNumber2}
                onChange={handleInputChange}
                placeholder="İletişim No 2"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="bankReceiver1"
                value={formData.bankReceiver1}
                onChange={handleInputChange}
                placeholder="Banka Alıcı Adı 1"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="bankName1"
                value={formData.bankName1}
                onChange={handleInputChange}
                placeholder="Banka Adı 1"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="iban1"
                value={formData.iban1}
                onChange={handleInputChange}
                placeholder="IBAN 1"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="bankReceiver2"
                value={formData.bankReceiver2}
                onChange={handleInputChange}
                placeholder="Banka Alıcı Adı 2"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="bankName2"
                value={formData.bankName2}
                onChange={handleInputChange}
                placeholder="Banka Adı 2"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="iban2"
                value={formData.iban2}
                onChange={handleInputChange}
                placeholder="IBAN 2"
              />
            </div>
          </div>

          <button type="submit">Kaydet</button>
        </form>

        {/* Hata ve Başarı Mesajları */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
      </div>
    </div>
  );
};

export default CompanyEdit;
