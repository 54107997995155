import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import { FaBars, FaTimes, FaUserCog, FaBuilding, FaCogs, FaUser, FaSignOutAlt, FaMapMarkedAlt } from 'react-icons/fa';
import api from '../Axios/axios';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [userCompanyId, setUserCompanyId] = useState('');
  const [hasTowerCrane, setHasTowerCrane] = useState(false);
  const [rentTowerCrane, setRentTowerCrane] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (name) => {
    setDropdownOpen(dropdownOpen === name ? null : name);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const companyId = decodedToken.CompanyId;
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    console.log(decodedToken);
    setUserCompanyId(companyId);
    setUserRole(role);
    
    console.log("Decoded Company ID:", companyId);
    console.log("Decoded Role:", role);

    // Eğer rol "Owner" ise, hasTowerCrane ve rentTowerCrane değerlerini false yap
    if (role === "Owner") {
      setHasTowerCrane(false);
      setRentTowerCrane(false);
    }
  };

  useEffect(() => {
    getUserFromToken();

    const fetchCompanyData = async () => {
      if (!userCompanyId || userRole === "Owner") return;

      try {
        const token = localStorage.getItem('token');
        const companyResponse = await api.get(`/Company/GetById?id=${userCompanyId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (companyResponse.data && companyResponse.data.data) {
          const companyData = companyResponse.data.data;
          setHasTowerCrane(companyData.hasTowerCrane);
          setRentTowerCrane(companyData.rentTowerCrane);

          console.log("hasTowerCrane:", companyData.hasTowerCrane);
          console.log("rentTowerCrane:", companyData.rentTowerCrane);
        } else {
          console.error("Şirket verileri alınamadı veya beklenmedik bir formatta geldi.");
        }
      } catch (error) {
        console.error('Şirket verileri alınırken hata oluştu:', error);
      }
    };

    fetchCompanyData();
  }, [userCompanyId, userRole]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/tracking" className="navbar-logo">TTC</Link>
        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>

        <div className={`nav-menu ${isOpen ? 'active' : ''}`}>
          {/* Takip Dropdown Menüsü */}
          <div
            className="dropdown"
            onMouseEnter={() => setDropdownOpen('tracking')}
            onMouseLeave={() => setDropdownOpen(null)}
          >
            <button
              className="dropbtn"
              onClick={() => toggleDropdown('tracking')}
            >
              Takip <FaMapMarkedAlt style={{ marginLeft: '8px' }} />
            </button>
            <div className={`dropdown-content ${dropdownOpen === 'tracking' ? 'show' : ''}`}>
              {(userRole === 'Owner' || hasTowerCrane) && (
                <Link to="/tracking" onClick={() => setIsOpen(false)}>Kule Vinçlerim</Link>
              )}
              {rentTowerCrane && (
                <Link to="/myrentals" onClick={() => setIsOpen(false)}>Kiraladığım Vinçler</Link>
              )}
            </div>
          </div>

          {(userRole === 'Owner' || userRole === 'Employee') && (
            <>
              <div
                className="dropdown"
                onMouseEnter={() => setDropdownOpen('user')}
                onMouseLeave={() => setDropdownOpen(null)}
              >
                <button
                  className="dropbtn"
                  onClick={() => toggleDropdown('user')}
                >
                  Kullanıcı İşlemleri <FaUserCog style={{ marginLeft: '8px' }} />
                </button>
                <div className={`dropdown-content ${dropdownOpen === 'user' ? 'show' : ''}`}>
                  <Link to="/user-list" onClick={() => setIsOpen(false)}>Kullanıcı Listesi</Link>
                  <Link to="/add-user" onClick={() => setIsOpen(false)}>Kullanıcı Ekle</Link>
                </div>
              </div>

              <div
                className="dropdown"
                onMouseEnter={() => setDropdownOpen('company')}
                onMouseLeave={() => setDropdownOpen(null)}
              >
                <button
                  className="dropbtn"
                  onClick={() => toggleDropdown('company')}
                >
                  Şirket İşlemleri <FaBuilding style={{ marginLeft: '8px' }} />
                </button>
                <div className={`dropdown-content ${dropdownOpen === 'company' ? 'show' : ''}`}>
                  <Link to="/company-list" onClick={() => setIsOpen(false)}>Şirket Listesi</Link>
                  <Link to="/add-company" onClick={() => setIsOpen(false)}>Şirket Ekle</Link>
                </div>
              </div>

              <div
                className="dropdown"
                onMouseEnter={() => setDropdownOpen('crane')}
                onMouseLeave={() => setDropdownOpen(null)}
              >
                <button
                  className="dropbtn"
                  onClick={() => toggleDropdown('crane')}
                >
                  Kule Vinç İşlemleri <FaCogs style={{ marginLeft: '8px' }} />
                </button>
                <div className={`dropdown-content ${dropdownOpen === 'crane' ? 'show' : ''}`}>
                  <Link to="/add-crane" onClick={() => setIsOpen(false)}>Vinç Ekle</Link>
                  <Link to="/cranerental" onClick={() => setIsOpen(false)}>Vinç Kiralama</Link>
                </div>
              </div>
            </>
          )}

          <Link to="/profile" className="nav-link" onClick={() => setIsOpen(false)}>
            Profil <FaUser style={{ marginLeft: '8px' }} />
          </Link>
          <button className="logout-btn" onClick={handleLogout}>
            <FaSignOutAlt style={{ marginRight: '8px' }} /> Çıkış Yap
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
