import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import api from '../Axios/axios'; // axios.js dosyasını kullanıyoruz
import './UserList.scss';

const UserList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]); // Rolleri tutmak için state
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchUsersAndRoles(); // Hem kullanıcıları hem de rolleri çekiyoruz
  }, []);

  // Her kullanıcıyı ayrı ayrı GetByUserId ile çekmek
  const fetchUsersAndRoles = async () => {
    try {
      const token = localStorage.getItem('token'); // Token alıyoruz

      // Kullanıcı ID'lerini alıyoruz (örneğin bir listeden)
      const usersResponse = await api.get('/User', {
        headers: {
          Authorization: `Bearer ${token}`, // Token otomatik olarak ekleniyor
        },
      });

      const userIds = usersResponse.data.data.map(user => user.id); // Tüm kullanıcı ID'leri
      const usersWithDetails = [];

      // Her kullanıcı için tek tek API çağrısı
      for (const id of userIds) {
        const userDetailResponse = await api.get(`/User/GetByUserId?userId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        usersWithDetails.push(userDetailResponse.data.data);
      }

      // Rolleri API'den çekiyoruz
      const rolesResponse = await api.get('/Auth/GetAllRoles', {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization başlığı ile gönderiyoruz
        },
      });

      const fetchedRoles = rolesResponse.data.data;

      // Kullanıcıları rollerle eşleştiriyoruz
      const usersWithRoles = usersWithDetails.map(user => {
        const userRole = fetchedRoles.find(role => role.id === user.roleId);
        return {
          ...user,
          roleName: userRole ? userRole.name : 'Rol Yok', // Eğer rol yoksa 'Rol Yok' yazılıyor
        };
      });

      // Kullanıcıları ve rolleri set ediyoruz
      setUsers(usersWithRoles);
      setRoles(fetchedRoles);

      console.log('Kullanıcılar ve roller:', usersWithRoles);

    } catch (error) {
      console.error('Kullanıcılar veya roller yüklenemedi:', error);
      setErrorMessage('Kullanıcılar veya roller yüklenemedi. Lütfen tekrar deneyin.');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/User`, {
        data: { id }, // Delete işlemi için id'yi gönderiyoruz
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(users.filter(user => user.id !== id)); // Kullanıcıyı listeden kaldırıyoruz
      setShowModal(false);
    } catch (error) {
      console.error('Kullanıcı silinemedi:', error);
      setErrorMessage('Kullanıcı silinemedi. Lütfen tekrar deneyin.');
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-user/${id}`); // Kullanıcı düzenleme sayfasına yönlendirme
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  // fullName oluşturma ve arama işlemi
  const filteredUsers = users.filter(user =>
    `${user.name} ${user.surname}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="user-list">
      <Navbar />
      <div className="company-list-container">
        <div className="company-list-header">
          <h2>Çalışanlar</h2>
          <div className="search-and-add">
            <input
              type="text"
              placeholder="Çalışan adına göre ara..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-bar"
            />
            <Link to="/add-user" className="add-company-btn">Çalışan Ekle</Link>
          </div>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Hata mesajı */}
        <table>
          <thead>
            <tr>
              <th>Ad Soyad</th>
              <th>Email</th>
              <th>Rol</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map(user => (
                <tr key={user.id}>
                  <td>{`${user.name} ${user.surname}`}</td> {/* Ad ve Soyad birleştiriliyor */}
                  <td>{user.email}</td>
                  <td>{user.role}</td> {/* Kullanıcı rolü roleId ile eşleştiriliyor */}
                  <td>
                    <button className="edit-btn" onClick={() => handleEdit(user.id)}>
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    <button className="delete-btn" onClick={() => openModal(user)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Aradığınız isimde bir çalışan bulunamadı.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>{`${selectedUser?.name} ${selectedUser?.surname}`} adlı çalışanı silmek istediğinizden emin misiniz?</h4>
            <div className="modal-buttons">
              <button className="confirm-btn" onClick={() => handleDelete(selectedUser.id)}>Evet</button>
              <button className="cancel-btn" onClick={closeModal}>Hayır</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
