import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import './CraneRental.scss';
import api from '../Axios/axios';
import {  useNavigate  } from 'react-router-dom';

const CraneRental = ({ role }) => {
  const [craneOwners, setCraneOwners] = useState([]);
  const [availableCranes, setAvailableCranes] = useState([]);
  const [rentalCompanies, setRentalCompanies] = useState([]);
  const [companySites, setCompanySites] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [selectedCrane, setSelectedCrane] = useState(null);
  const [rentalDetails, setRentalDetails] = useState({
    rentalCompany: '',
    site: '',
    rentalDuration: '',
    rentalFee: '',
    startDate: '',
    endDate: '',
    installationHeight: '',
    type: '',
    hasWalkingGear: '',
    commissionRate: '',
    notes: [{ id: 1, note: '' }],
  });
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newSite, setNewSite] = useState({ name: '', address: '' });

  useEffect(() => {
    const fetchCraneOwners = async () => {
      try {
        const response = await api.get('/Company');
        const filteredOwners = response.data.data.filter(owner => owner.hasTowerCrane);
        setCraneOwners(filteredOwners);
      } catch (error) {
        console.error('Kule vinç sahipleri yüklenemedi:', error);
      }
    };

    const fetchRentalCompanies = async () => {
      try {
        const response = await api.get('/Company');
        const filteredCompanies = response.data.data.filter(company => company.rentTowerCrane);
        setRentalCompanies(filteredCompanies);
      } catch (error) {
        console.error('Kiralayan firmalar yüklenemedi:', error);
      }
    };

    fetchCraneOwners();
    fetchRentalCompanies();
  }, []);
 
  const handleOwnerChange = async (e) => {
    setSelectedOwner(e.target.value);
    try {
      const response = await api.get('/TowerCrane');
      const filteredCranes = response.data.data.filter(
        crane => crane.ownerId === e.target.value && crane.status === 'Active'
      );
      setAvailableCranes(filteredCranes);
      setSelectedCrane(null);
    } catch (error) {
      console.error('Kule vinçler yüklenemedi:', error);
    }
  };

  const handleCraneChange = (e) => {
    const selectedCraneData = availableCranes.find(crane => crane.id === parseInt(e.target.value));
    setSelectedCrane(selectedCraneData);
  };

  const handleRentalCompanyChange = async (e) => {
    const companyId = e.target.value;
    setRentalDetails({ ...rentalDetails, rentalCompany: companyId });
    try {
      const response = await api.get('/WorkSite');
      const filteredSites = response.data.data.filter(site => site.companyId === companyId);
      setCompanySites(filteredSites);
    } catch (error) {
      console.error('Şantiyeler yüklenemedi:', error);
    }
  };

  const handleRentalDetailChange = (e) => {
    const { name, value } = e.target;
    setRentalDetails({
      ...rentalDetails,
      [name]: value,
    });

    if (name === 'rentalDuration' && rentalDetails.startDate) {
      const startDate = new Date(rentalDetails.startDate);
      startDate.setMonth(startDate.getMonth() + parseInt(value, 10));
      setRentalDetails((prevState) => ({
        ...prevState,
        endDate: startDate.toISOString().slice(0, 10),
      }));
    }
  };

  const handleFileChange = (e, fileType) => {

    var f = files.find(x => x.fileType === fileType);
    if(f){
      f.file = Array.from(e.target.files) 
    }
    else{
      files.push({
        fileType: fileType, 
        file: Array.from(e.target.files) 
      })
    }
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...rentalDetails.notes];
    updatedNotes[index].note = value;
    setRentalDetails({ ...rentalDetails, notes: updatedNotes });
  };

  const addNote = () => {
    setRentalDetails((prevState) => ({
      ...prevState,
      notes: [...prevState.notes, { id: prevState.notes.length + 1, note: '' }],
    }));
  };
// rentalPeriod değiştiğinde çağırılacak fonksiyon
const calculateRentalEndDate = () => {
  const { startDate, rentalDuration } = rentalDetails;
  if (startDate && rentalDuration) {
    const start = new Date(startDate);
    start.setMonth(start.getMonth() + parseInt(rentalDuration, 10));
    setRentalDetails((prevState) => ({
      ...prevState,
      endDate: start.toISOString().slice(0, 10),
    }));
  }
};

// rentalDuration ve startDate değiştiğinde otomatik hesaplama yapılması için useEffect kullanımı
useEffect(() => {
  calculateRentalEndDate();
}, [rentalDetails.startDate, rentalDetails.rentalDuration]);
  const handleNewSiteAdd = async () => {
    if (rentalDetails.rentalCompany && newSite.name && newSite.address) {
      try {
        await api.post('/WorkSite', {
          name: newSite.name,
          companyId: rentalDetails.rentalCompany,
        });
        setNewSite({ name: '', address: '' });
        setShowModal(false);
        handleRentalCompanyChange({ target: { value: rentalDetails.rentalCompany } });
      } catch (error) {
        console.error('Şantiye eklenemedi:', error);
      }
    }
  };

  const handleRentalSubmit = async () => {
    if (!rentalDetails.rentalCompany || !selectedCrane || !rentalDetails.site) {
      console.error('Eksik veya hatalı veri. Lütfen tüm alanları doldurun.');
      return;
    }

    const formData = new FormData();
    formData.append('RentingCompanyId', rentalDetails.rentalCompany);
    formData.append('TowerCraneId', selectedCrane.id);
    formData.append('WorkSiteId', rentalDetails.site);
    formData.append('InstalledHeight', rentalDetails.installationHeight);
    formData.append('RentalPeriod', rentalDetails.rentalDuration);
    formData.append('Rent', rentalDetails.rentalFee);
    formData.append('CommissionRate', rentalDetails.commissionRate);
    formData.append('RentalStartDate', rentalDetails.startDate);
    formData.append('RentalEndDate', rentalDetails.endDate);
    // formData.append('Notes', JSON.stringify(rentalDetails.notes));

    console.log(rentalDetails.notes);
    rentalDetails.notes.forEach((item, index) => { 
      formData.append(`Notes[${index}]`,item.note); 
    });
    files.forEach((item, index) => {
      formData.append(`Files[${index}].file`, item.file[0]); 
      formData.append(`Files[${index}].fileType`, item.fileType);  
    });


    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/Rental', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Kiralama başarıyla gerçekleşti:', response.data);
      navigate(0);

    } catch (error) {
      if (error.response) {
        console.error('Kiralama hatası:', error.response.data);
      } else {
        console.error('Kiralama hatası:', error.message);
      }
    }
  };
  return (
    <div className="crane-rental-page">
      <Navbar />
      <div className="rental-form">
        <h2>Kiraya Gönder</h2>

        <div className="form-row">
          <div className="form-group">
            <label>Kule Vinç Sahibi Firma</label>
            <select value={selectedOwner} onChange={handleOwnerChange} disabled={role === 'owner'}>
              <option value="">Firma Seçin</option>
              {craneOwners.map((owner) => (
                <option key={owner.id} value={owner.id}>{owner.name}</option>
              ))}
            </select>
          </div>

          {availableCranes.length > 0 && (
            <div className="form-group">
              <label>Kule Vinç Seçin</label>
              <select value={selectedCrane?.id || ''} onChange={handleCraneChange} disabled={role === 'owner'}>
                <option value="">Vinç Seçin</option>
                {availableCranes.map((crane) => (
                  <option key={crane.id} value={crane.id}>{crane.serialNo}</option>
                ))}
              </select>
            </div>
          )}
        </div>

        {selectedCrane && (
          <div className="crane-info">
            <div className="info-row">
              <span><strong>Seri No:</strong> {selectedCrane.serialNo}</span>
              <span><strong>Marka:</strong> {selectedCrane.brandName}</span>
              <span><strong>Vinç Kodu:</strong> {selectedCrane.code}</span>
            </div>
            <div className="info-row">
              <span><strong>Yükseklik:</strong> {selectedCrane.height}m</span>
              <span><strong>Maksimum Tonaj:</strong> {selectedCrane.maxTonnage}</span>
            </div>
          </div>
        )}

        {selectedCrane && role !== 'owner' && (
          <>
            <div className="form-row">
              <div className="form-group">
                <label>Kiralayan Firma</label>
                <select name="rentalCompany" value={rentalDetails.rentalCompany} onChange={handleRentalCompanyChange}>
                  <option value="">Firma Seçin</option>
                  {rentalCompanies.map((company) => (
                    <option key={company.id} value={company.id}>{company.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Şantiye</label>
                <select name="site" value={rentalDetails.site} onChange={handleRentalDetailChange}>
                  <option value="">Şantiye Seçin</option>
                  {companySites.map((site) => (
                    <option key={site.id} value={site.id}>{site.name}</option>
                  ))}
                </select>
                <small className="add-site-link" onClick={() => setShowModal(true)}>Yeni Şantiye Ekle</small>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Şaseli mi? Ankarajlı mı?</label>
                <select name="chassisType" value={rentalDetails.chassisType} onChange={handleRentalDetailChange}>
                  <option value="">Seçin</option>
                  <option value="Şaseli">Şaseli</option>
                  <option value="Ankarajlı">Ankarajlı</option>
                </select>
              </div>
              <div className="form-group">
                <label>Yürüyüş Takımı Var mı?</label>
                <select name="walkingSystem" value={rentalDetails.walkingSystem} onChange={handleRentalDetailChange}>
                  <option value="">Seçin</option>
                  <option value="Var">Var</option>
                  <option value="Yok">Yok</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Kaç Metre Yüksekliğe Kuruluyor?</label>
                <input type="number" name="installationHeight" value={rentalDetails.installationHeight} onChange={handleRentalDetailChange} />
              </div>
              <div className="form-group">
                <label>Kira Süresi (Ay)</label>
                <input type="number" name="rentalDuration" value={rentalDetails.rentalDuration} onChange={handleRentalDetailChange} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Kira Bedeli</label>
                <input type="number" name="rentalFee" value={rentalDetails.rentalFee} onChange={handleRentalDetailChange} />
              </div>
              <div className="form-group">
                <label>Komisyon Oranı</label>
                <input type="number" name="commissionRate" value={rentalDetails.commissionRate} onChange={handleRentalDetailChange} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Kira Başlangıç Tarihi</label>
                <input type="date" name="startDate" value={rentalDetails.startDate} onChange={handleRentalDetailChange} />
              </div>
              <div className="form-group">
                <label>Kira Bitiş Tarihi</label>
                <input type="date" name="endDate" value={rentalDetails.endDate} onChange={handleRentalDetailChange} disabled />
                </div>
            </div>

            {/* Dosya Yükleme Alanları */}
            <div className="form-row">
              <div className="form-group">
                <label>Teklif Dosyası</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'Offer')} />
              </div>
              <div className="form-group">
                <label>Sözleşme Dosyası</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'Contract')} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Servis Formu</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'ServiceForm')} />
              </div>
              <div className="form-group">
                <label>Görseller</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif, .webp" multiple onChange={(e) => handleFileChange(e, 'Image')} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Ödeme Dekontu</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'paymentDoc')} />
              </div>
              <div className="form-group">
                <label>Çek Görselleri</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'checkImages')} />
              </div>
            </div>

            {/* Notlar dinamik olarak ekleniyor */}
            <div className="form-row notes-container">
              {rentalDetails.notes.map((note, index) => (
                <div key={index} className="form-group note-group">
                <label>Notlar</label>
                  
                  <input
                    type="text"
                    name={`note-${index}`}
                    value={note.note}
                    onChange={(e) => handleNoteChange(index, e.target.value)}
                    placeholder={`Not ${index + 1}`}
                  />
                </div>
              ))}
              <button type="button" onClick={addNote} className="add-note-btn">+ Not Ekle</button>
            </div>

            <button className="submit-btn" onClick={handleRentalSubmit}>Kiraya Gönder</button>
          </>
        )}

        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Yeni Şantiye Ekle</h3>
              <div className="form-group modal-form">
                <label>Şantiye Adı</label>
                <input
                  type="text"
                  value={newSite.name}
                  onChange={(e) => setNewSite({ ...newSite, name: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Şantiye Adresi</label>
                <input
                  type="text"
                  value={newSite.address}
                  onChange={(e) => setNewSite({ ...newSite, address: e.target.value })}
                />
              </div>
              <button className="add-site-btn" onClick={handleNewSiteAdd}>Ekle</button>
              <button className="close-modal-btn" onClick={() => setShowModal(false)}>Kapat</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CraneRental;
