import React, { useState, useEffect } from 'react'; 
import Navbar from '../Navbar/Navbar';
import './RentalEdit.scss';
import { useParams, useNavigate  } from 'react-router-dom';
import api from '../Axios/axios';

const RentalEdit = ({ role }) => {
  const { rentalId } = useParams();
  const navigate = useNavigate();

  const [rentalDetails, setRentalDetails] = useState({
    craneOwner: '',
    serialNo: '',
    brand: '',
    craneCode: '',
    height: '',
    maxTonnage: '',
    rentalCompany: '',
    site: '',
    chassisType: '',
    walkingSystem: '',
    installationHeight: '',
    rentalDuration: '',
    rentalFee: '',
    commissionRate: '',
    startDate: '',
    endDate: '',
    hasExtraPeriod: false,
    extraPeriod: '',
    notes: [{ id: 1, note: '' }]
  });
  
  const [files, setFiles] = useState([]);
  const [showEndRentalModal, setShowEndRentalModal] = useState(false);
  const [showDepotModal, setShowDepotModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [newDepotName, setNewDepotName] = useState('');
  const [newDepotAddress, setNewDepotAddress] = useState('');

  useEffect(() => {
    if (rentalId) {
      fetchRentalDetails(rentalId);
    }
    fetchWarehouses();
  }, [rentalId]);

  const fetchRentalDetails = async (id) => {
    try {
      const rentalResponse = await api.get(`/Rental/GetById?id=${id}`);
      const rentalData = rentalResponse.data.data;

      console.log("rentalDataFiles : ", rentalData.files)

      rentalData.files.forEach((item, index) => {

        var isExist = files.find(x => x.id === item.id)
        if(!isExist){
            files.push({id : item.id, file : null, fileType : item.fileType, fileName : item.fileName})
        }
      })

      console.log("files : ", files)

      const towerCraneResponse = await api.get(`/TowerCrane/GetById?id=${rentalData.towerCraneId}`);
      const towerCraneData = towerCraneResponse.data.data;

      setRentalDetails({
        craneOwner: rentalData.owner || '',
        serialNo: towerCraneData.serialNo || '',
        brand: towerCraneData.brand || '',
        craneCode: towerCraneData.model || '',
        height: towerCraneData.height || '',
        maxTonnage: towerCraneData.maxTonnage || '',
        rentalCompany: rentalData.rentingCompany || '',
        site: rentalData.workSite || '',
        chassisType: towerCraneData.type === "Chassis" ? "Şaseli" : "Ankarajlı",
        walkingSystem: towerCraneData.hasWalkingGear ? "Var" : "Yok",
        installationHeight: rentalData.installedHeight || '',
        rentalDuration: rentalData.rentalPeriod || '',
        rentalFee: rentalData.rent || '',
        commissionRate: rentalData.commissionRate || '',
        startDate: rentalData.rentalStartDate.split('T')[0] || '',
        endDate: rentalData.rentalEndDate.split('T')[0] || '',
        hasExtraPeriod: rentalData.hasExtraPeriod,
        extraPeriod: rentalData.extraPeriod,
        notes: rentalData.notes, 
      });
        // rentalData.files'ı organize ederek her bir fileType için file ve fileName ile ekle
        const organizedFiles = rentalData.files.map(file => ({
          fileType: file.fileType,
          file: file.fileName, // fileName'i file olarak set ediyoruz
          id: file.id
      }));
        
        console.log("Organized Files:", organizedFiles); // Organized files loglama
        setFiles(organizedFiles); // Doğrudan dizi olarak dosyaları ekleme

    } catch (error) {
      console.error('Veri çekme hatası:', error);
    }
  };
  useEffect(() => {
    console.log("Updated files:", files);
  }, [files]); // `files` durumu değiştikçe bu etki çalışır
  const fetchWarehouses = async () => {
    try {
      const response = await api.get('/Warehouse');
      setWarehouses(response.data.data);
    } catch (error) {
      console.error('Depolar yüklenemedi:', error);
    }
  };

  const handleRentalDetailChange = (e) => {
    const { name, value, type } = e.target;
  
    setRentalDetails((prevState) => ({
      ...prevState,
      [name]: type === 'number' ? parseFloat(value) || '' : value, // Eğer input number ise sayıya çevir, değilse string olarak ayarla
    }));
  
    // Özel işlemleri burada kontrol edin
    if (name === 'rentalDuration' && rentalDetails.startDate) {
      const startDate = new Date(rentalDetails.startDate);
      startDate.setMonth(startDate.getMonth() + parseInt(value, 10));
      setRentalDetails((prevState) => ({
        ...prevState,
        endDate: startDate.toISOString().slice(0, 10),
      }));
    }
  
    if (name === 'extraPeriod' && rentalDetails.hasExtraPeriod && rentalDetails.startDate) {
      const newExtraPeriod = parseInt(value, 10);
      if (isNaN(newExtraPeriod) || newExtraPeriod < 0) {
        setRentalDetails((prevState) => ({
          ...prevState,
          extraPeriod: '',
        }));
        return;
      }
  
      const currentExtraPeriod = parseInt(rentalDetails.extraPeriod || 0, 10);
      const periodDifference = newExtraPeriod - currentExtraPeriod;
  
      const updatedEndDate = new Date(rentalDetails.endDate || rentalDetails.startDate);
      updatedEndDate.setMonth(updatedEndDate.getMonth() + periodDifference);
      setRentalDetails((prevState) => ({
        ...prevState,
        extraPeriod: newExtraPeriod,
        endDate: updatedEndDate.toISOString().slice(0, 10),
      }));
    }
  };
  
  
  const handleFileChange = (e, fileType) => {

    var allFiles = e.target.files;

    for (let index = 0; index < allFiles.length; index++) {
      const element = allFiles[index];

      var f = files.find(x => x.fileName === element.name && x.fileType === fileType);
      if(f){
        f.file = Array.from(element) 
        f.file = [element]
      }
      else{
        files.push({
          id : 0,
          fileType: fileType, 
          file: Array.from(element), 
          file: [element],
          fileName : element.name
        })
      }
    }
};
  
  
const handleNoteChange = (index, value) => {
  const updatedNotes = [...rentalDetails.notes];
  updatedNotes[index].note = value; // Doğru alan güncelleniyor
  setRentalDetails({ ...rentalDetails, notes: updatedNotes });
};

const addNote = () => {
  setRentalDetails((prevState) => ({
    ...prevState,
    notes: [...prevState.notes, { id: prevState.notes.length + 1, note: '' }],
  }));
};


const handleRentalSubmit = async () => {
    try {
      const rentalData = {
        Id: rentalId,
        InstalledHeight: rentalDetails.installationHeight,
        RentalPeriod: rentalDetails.rentalDuration,
        Rent: rentalDetails.rentalFee,
        Type: rentalDetails.chassisType === "Şaseli" ? "Chassis" : "Anchored",
        HasWalkingGear: rentalDetails.walkingSystem === "Var",
        commissionRate: rentalDetails.commissionRate,
        RentalStartDate: rentalDetails.startDate,
        RentalEndDate: rentalDetails.endDate,
        HasExtraTime: rentalDetails.hasExtraPeriod,
        ExtraPeriod: rentalDetails.extraPeriod || 0,
      };

      const formData = new FormData();
      console.log(rentalData);

      Object.keys(rentalData).forEach(key => {
        formData.append(key, rentalData[key]);
      });

      // Notları FormData'ya ekleyin
      rentalDetails.notes.forEach((item, index) => { 
        formData.append(`Notes[${index}]`, item.note); 
      });
        // Dosyaları formData'ya ekle
        if (files && files.length > 0) {
          files.forEach((item, index) => {
            formData.append(`Files[${index}].id`, item.id);
            formData.append(`Files[${index}].file`, item.file[0]);
            formData.append(`Files[${index}].fileType`, item.fileType);
          });
      }
      
    // FormData içeriğini yazdır
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }      
    const response = await api.put(`/Rental`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Yükleme İlerlemesi: ${percentCompleted}%`);
          // Progress bar için setState kullanabilirsiniz
        },
      });

      console.log('Kiralama güncelleme başarılı:', response.data);
    } catch (error) {
      console.error('Kiralama güncelleme hatası:', error);
    }
  };

const handleRentalEnd = async () => {
    try {
      await api.patch('/Rental', { id: rentalId, warehouseId });
      console.log('Kiralama başarıyla sona erdi');
      setShowEndRentalModal(false);
      navigate(0);
    } catch (error) {
      console.error('Kiralama bitirme hatası:', error);
    }
  };

  const handleAddDepot = async () => {
    if (newDepotName && newDepotAddress) {
      try {
        const response = await api.post('/Warehouse', { name: newDepotName, address: newDepotAddress });
        const addedDepot = response.data?.data || {};
        if (addedDepot.id) {
          setWarehouses([...warehouses, addedDepot]);
          setNewDepotName('');
          setNewDepotAddress('');
          setShowDepotModal(false);
          navigate(0);

        }
      } catch (error) {
        console.error('Depo eklenemedi:', error);
      }
    }
  };

  return (
    <div className="rental-edit-page">
      <Navbar />
      <div className="rental-form">
        <h2>Kira Düzenle</h2>
  
        <div className="form-row">
          <div className="form-group">
            <label>Kule Vinç Sahibi Firma</label>
            <input
              type="text"
              name="craneOwner"
              value={rentalDetails.craneOwner}
              onChange={handleRentalDetailChange}
              disabled
            />
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group craneinfos">
            <label><b>Seri No:</b> {rentalDetails.serialNo}</label>
            <label><b>Marka:</b> {rentalDetails.brand}</label>
            <label><b>Vinç Kodu:</b> {rentalDetails.craneCode}</label>
            <label><b>Yükseklik:</b> {rentalDetails.height} m</label>
            <label><b>Maksimum Tonaj:</b> {rentalDetails.maxTonnage}T</label>
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Kiralayan Firma</label>
            <input
              type="text"
              name="rentalCompany"
              value={rentalDetails.rentalCompany}
              onChange={handleRentalDetailChange}
              disabled
            />
          </div>
          <div className="form-group">
            <label>Şantiye</label>
            <input
              type="text"
              name="site"
              value={rentalDetails.site}
              onChange={handleRentalDetailChange}
              disabled
            />
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Şaseli mi? Ankarajlı mı?</label>
            <select name="chassisType" value={rentalDetails.chassisType} onChange={handleRentalDetailChange} disabled>
              <option value="">Seçin</option>
              <option value="Şaseli">Şaseli</option>
              <option value="Ankarajlı">Ankarajlı</option>
            </select>
          </div>
          <div className="form-group">
            <label>Yürüyüş Takımı Var mı?</label>
            <select name="walkingSystem" value={rentalDetails.walkingSystem} onChange={handleRentalDetailChange} disabled>
              <option value="">Seçin</option>
              <option value="Var">Var</option>
              <option value="Yok">Yok</option>
            </select>
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Kaç Metre Yüksekliğe Kuruluyor?</label>
            <input
              type="number"
              name="installationHeight"
              value={rentalDetails.installationHeight}
              onChange={handleRentalDetailChange}
            />
          </div>
          <div className="form-group">
            <label>Kira Süresi (Ay)</label>
            <input
              type="number"
              name="rentalDuration"
              value={rentalDetails.rentalDuration}
              onChange={handleRentalDetailChange}
            />
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Kira Bedeli</label>
            <input
              type="number"
              name="rentalFee"
              value={rentalDetails.rentalFee}
              onChange={handleRentalDetailChange}
            />
          </div>
          <div className="form-group">
            <label>Komisyon Oranı (%)</label>
            <input
              type="number"
              name="commissionRate"
              value={rentalDetails.commissionRate}
              onChange={handleRentalDetailChange}
            />
          </div>
        </div>
  
        <div className="form-row">
          <div className="form-group">
            <label>Kira Başlangıç Tarihi</label>
            <input
              type="date"
              name="startDate"
              value={rentalDetails.startDate}
              onChange={handleRentalDetailChange}
            />
          </div>
          <div className="form-group">
            <label>Kira Bitiş Tarihi</label>
            <input
              type="date"
              name="endDate"
              value={rentalDetails.endDate}
              onChange={handleRentalDetailChange}
            />
          </div>
        </div>
  
            {/* Dosya Yükleme Alanları */}
            <div className="form-row">
              <div className="form-group">
                <label>Teklif Dosyası</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'Offer')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'Offer')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>

              <div className="form-group">
                <label>Sözleşme Dosyası</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'Contract')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'Contract')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Servis Formu</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'ServiceForm')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'ServiceForm')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-group">
                <label>Görseller</label>
                <input type="file" accept=".jpg, .jpeg, .png, .gif, .webp"   multiple onChange={(e) => handleFileChange(e, 'Image')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'Image')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Ödeme Dekontu</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'paymentDoc')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'paymentDoc')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-group">
                <label>Çek Görselleri</label>
                <input type="file" multiple onChange={(e) => handleFileChange(e, 'checkImages')} />
                <div className="file-names">
                  {files
                    .filter((file) => file.fileType === 'checkImages')
                    .map((file, index) => (
                      <div key={index} className="file-name">
                        {(index+1)+"."+ file.file || 'Dosya Yok'}
                      </div>
                    ))}
                </div>
              </div>
            </div>

  
        <div className="form-row">
          <div className="form-group checkbox-group">
            <label>Uzatma Süresi Var mı?</label>
            <input
              type="checkbox"
              name="hasExtraPeriod"
              checked={rentalDetails.hasExtraPeriod}
              onChange={(e) => setRentalDetails({ ...rentalDetails, hasExtraPeriod: e.target.checked })}
            />
          </div>
          {rentalDetails.hasExtraPeriod && (
            <div className="form-group">
              <label>Uzatma Süresi (Ay)</label>
              <input
                type="number"
                name="extraPeriod"
                value={rentalDetails.extraPeriod}
                onChange={handleRentalDetailChange}
              />
            </div>
          )}
        </div>

        {/* Notlar */}
        <div className="form-row notes-container">
        <label>Notlar</label>

          {rentalDetails.notes.map((note, index) => (
            <div key={index} className="form-group note-group">

              <input
                type="text"
                name={`note-${index}`}
                value={note.note || ''} // Her notu input alanında göster
                onChange={(e) => handleNoteChange(index, e.target.value)}
                placeholder={`Not ${index + 1}`}
              />
            </div>
          ))}
          <button type="button" onClick={addNote} className="add-note-btn">+ Not Ekle</button>
        </div>

        <div className="button-group">
          <button className="submit-btn" onClick={handleRentalSubmit}>Kirayı Güncelle</button>
          <button className="end-rental-btn" onClick={() => setShowEndRentalModal(true)}>Kirayı Bitir</button>
        </div>
  
        {showEndRentalModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Kiralama Bitir</h3>
              <div className="form-group">
                <label>Depoya Gönder</label>
                <select value={warehouseId} onChange={(e) => setWarehouseId(e.target.value)}>
                  <option value="">Depo Seçin</option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                  ))}
                </select>
                <small className="add-link" onClick={() => setShowDepotModal(true)}>+ Depo Ekle</small>
              </div>
              <div className="form-group">

              <button className="confirm-btn" onClick={handleRentalEnd}>Onayla</button>
              <button className="close-btn" onClick={() => setShowEndRentalModal(false)}>Kapat</button>
            </div>
            </div>
          </div>
        )}
  
        {showDepotModal && (
          <div className="modal">
            <div className="modal-content">
              <h3>Yeni Depo Ekle</h3>
              <input
                type="text"
                value={newDepotName}
                onChange={(e) => setNewDepotName(e.target.value)}
                placeholder="Depo Adı"
                required
              />
              <input
                type="text"
                value={newDepotAddress}
                onChange={(e) => setNewDepotAddress(e.target.value)}
                placeholder="Depo Adresi"
                required
              />
              <button onClick={handleAddDepot}>Ekle</button>
              <button onClick={() => setShowDepotModal(false)}>İptal</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RentalEdit;
