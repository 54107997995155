
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';


const getBaseURL = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:5058/api'; // Geliştirme ortamı için
  } else {
    const protocol = window.location.protocol; // http: veya https: alır
    return `${protocol}//api.kulevinctakip.com/api`; // Canlı ortam için
  }
};
// Axios yapılandırması
const api = axios.create({
  baseURL: getBaseURL(), // API'nin temel URL'si
  headers: {
    'Content-Type': 'application/json',
  },
});
// Oturum süresi dolduğunda veya hata aldığında yönlendirme
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 404)) {
      // Oturum geçersiz veya kaynak bulunamadıysa login sayfasına yönlendirme
      const navigate = useNavigate();
      localStorage.removeItem('token'); // Geçersiz token'ı kaldırma
      navigate('/login'); // Giriş sayfasına yönlendirme
    }
    return Promise.reject(error);
  }
);
// Her istekten önce token kontrol edilir
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Eğer token varsa header'a ekle
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
