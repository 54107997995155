import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './MyRentals.scss';
import api from '../Axios/axios';
import { FaPencilAlt, FaArrowRight } from 'react-icons/fa';

const MyRentals = () => {
  const navigate = useNavigate();
  const [towerCranes, setTowerCranes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [filters, setFilters] = useState({
    serialNumber: '',
    brand: '',
    model: '',
    height: '',
    tonnage: '',
    status: 'all',
    workSite: '',
  });

  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const companyId = decodedToken.CompanyId;
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    setUserCompanyId(companyId);
    setUserRole(role);
  };

  const fetchAllRentals = async () => {
    try {
      const rentalResponse = await api.get('/Rental');
      const allRentals = rentalResponse.data.data;
      setRentals(allRentals);
    } catch (error) {
      console.error('Kiralama bilgileri yüklenemedi:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const craneResponse = await api.get('/TowerCrane');
        setTowerCranes(craneResponse.data.data);

        const companyResponse = await api.get('/Company');
        setCompanies(companyResponse.data.data);

        const brandResponse = await api.get('/Brand');
        setBrands(brandResponse.data.data);

        await fetchAllRentals();
      } catch (error) {
        console.error('Veriler yüklenemedi:', error);
      }
    };

    getUserFromToken();
    fetchData();
  }, [userCompanyId]);

  const handleFilterChange = (e, filterName) => {
    setFilters({
      ...filters,
      [filterName]: e.target.value,
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR');
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((brand) => brand.id === brandId);
    return brand ? brand.name : '-';
  };

  const getRentalDetails = (crane) => {
    // Aktif kiralamayı ve kiralayan şirketin kimliğini kontrol et
    const activeRental = rentals.find(
      (rental) => rental.towerCraneId === crane.id && rental.isActive && rental.rentingCompanyId === userCompanyId
    );

    if (activeRental) {
      return {
        startDate: formatDate(activeRental.rentalStartDate),
        endDate: formatDate(activeRental.rentalEndDate),
        fee: activeRental.rent ? `${activeRental.rent} TL` : '-',
        rentalDuration: activeRental.rentalPeriod || '-',
        workSite: activeRental.workSite || '-',
        status: 'Inused',
        rentalCompany: activeRental.rentingCompany || '-',
        rentalId: activeRental.id,
      };
    }

    // Eğer aktif kiralama yoksa
    return {
      startDate: '-',
      endDate: '-',
      fee: '-',
      rentalDuration: '-',
      workSite: crane.warehouse || '-',
      status: 'Active',
      rentalCompany: '-',
      rentalId: null,
    };
  };

  const filteredTowerCranes = towerCranes
    .filter((crane) => {
      const rentalDetails = getRentalDetails(crane);
      if (userRole === 'Company' && rentalDetails.rentalCompany === '-') return false;

      const matchesSerialNumber = !filters.serialNumber || crane.serialNo.toLowerCase().includes(filters.serialNumber.toLowerCase());
      const matchesBrand = !filters.brand || crane.brandId.toString() === filters.brand;
      const matchesModel = !filters.model || crane.model === filters.model;
      const matchesHeight = !filters.height || crane.height.toString() === filters.height;
      const matchesTonnage = !filters.tonnage || crane.maxTonnage.toString() === filters.tonnage;
      const matchesStatus = filters.status === 'all' || rentalDetails.status === filters.status;
      const matchesWorkSite = !filters.workSite || rentalDetails.workSite === filters.workSite;

      return matchesSerialNumber && matchesBrand && matchesModel && matchesHeight && matchesTonnage && matchesStatus && matchesWorkSite;
    })
    .sort((a, b) => {
      const rentalA = getRentalDetails(a);
      const rentalB = getRentalDetails(b);
      return rentalA.status === 'Active' ? -1 : 1;
    });

  const uniqueBrands = Array.from(new Set(filteredTowerCranes.map((crane) => crane.brandId)));
  const uniqueModels = Array.from(new Set(filteredTowerCranes.map((crane) => crane.model)));
  const uniqueHeights = Array.from(new Set(filteredTowerCranes.map((crane) => crane.height)));
  const uniqueTonnages = Array.from(new Set(filteredTowerCranes.map((crane) => crane.maxTonnage)));
  const uniqueWorkSites = Array.from(new Set(filteredTowerCranes.map((crane) => getRentalDetails(crane).workSite)));

  const handleEdit = (rentalId) => {
    navigate(`/rentaledit/${rentalId}`);
  };

  const handleRentalPage = (craneId) => {
    navigate(`/rentalpage/${craneId}`);
  };

  const getStatusClass = (status) => {
    return status === 'Inused' ? 'row-used' : 'row-active';
  };

  return (
    <div className="my-rentals">
      <Navbar />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Seri Numarası</th>
              <th>Marka</th>
              <th>Model</th>
              <th>Yükseklik</th>
              <th>Tonaj</th>
              <th>Durum</th>
              <th>Kira Başlangıç Tarihi</th>
              <th>Kira Bitiş Tarihi</th>
              <th>Ücret</th>
              <th>Kira Süresi (Ay)</th>
              <th>Şantiye/Depo</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredTowerCranes.map((crane, index) => {
              const rentalDetails = getRentalDetails(crane);
              return (
                <tr key={crane.id} className={getStatusClass(rentalDetails.status)}>
                  <td>{crane.serialNo}</td>
                  <td>{getBrandName(crane.brandId)}</td>
                  <td>{crane.model || '-'}</td>
                  <td>{crane.height}m</td>
                  <td>{crane.maxTonnage}T</td>
                  <td>{rentalDetails.status === 'Inused' ? 'Kirada' : 'Boşta'}</td>
                  <td>{rentalDetails.startDate}</td>
                  <td>{rentalDetails.endDate}</td>
                  <td>{rentalDetails.fee}</td>
                  <td>{rentalDetails.rentalDuration}</td>
                  <td>{rentalDetails.workSite || '-'}</td>
                  <td>
                    <div className="btn-container">
                      {userRole === 'Company' && rentalDetails.rentalId && (
                        <button className="small-btn edit-btn" onClick={() => handleEdit(rentalDetails.rentalId)}>
                          <FaPencilAlt />
                        </button>
                      )}
                      <button className="small-btn details-btn" onClick={() => handleRentalPage(crane.id)}>
                        <FaArrowRight />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyRentals;
