import React, { useState, useEffect } from 'react'; 
import Navbar from '../Navbar/Navbar';
import './AddUser.scss';
import api from '../Axios/axios'; // Axios'u merkezi yapılandırmadan getiriyoruz
import {  useNavigate  } from 'react-router-dom';

const AddUser = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '', 
    roleId: '', // roleId burada dinamik olarak atanacak
    tc: '',
    email: '',
    phoneNumber: '',
    password: ''
  });
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]); // Rolleri tutmak için state
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Sayfa yüklendiğinde rolleri çekiyoruz
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alıyoruz
        const response = await api.get('/Auth/GetAllRoles', {
          headers: {
            Authorization: `Bearer ${token}` // Token'ı Authorization başlığı ile gönderiyoruz
          }
        });
        setRoles(response.data.data); // API'den gelen rolleri state'e ekliyoruz
      } catch (error) {
        console.error('Roller yüklenemedi:', error);
        setErrorMessage('Roller yüklenemedi. Lütfen tekrar deneyin.');
      }
    };
    
    fetchRoles();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Şifre doğrulama
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  // TC Kimlik doğrulama
  const validateTc = (tc) => {
    return /^[0-9]{11}$/.test(tc); // 11 haneli rakam kontrolü
  };

  // Telefon numarası doğrulama
  const validatePhoneNumber = (phoneNumber) => {
    return /^[0-9]{10,15}$/.test(phoneNumber); // 10 ila 15 haneli rakam kontrolü
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Şifre doğrulama
    if (!validatePassword(formData.password)) {
      setPasswordError('Şifre en az 8 karakter, bir büyük harf ve bir küçük harf içermelidir.');
      return;
    } else {
      setPasswordError('');
    }

    // TC Kimlik No ve Telefon Numarası doğrulaması
    if (!validateTc(formData.tc)) {
      setErrorMessage('Geçerli bir TC Kimlik No giriniz.');
      return;
    }

    if (!validatePhoneNumber(formData.phoneNumber)) {
      setErrorMessage('Geçerli bir telefon numarası giriniz.');
      return;
    }

    // Zorunlu alanlar
    const requestData = {
      name: formData.name,
      surname: formData.surname,
      roleId: formData.roleId,  // Dinamik olarak roleId çekiyoruz
      tc: formData.tc,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: formData.password
    };

    console.log('Gönderilen veriler:', requestData);
    try {
      const response = await api.post('/User', requestData);

      // Eğer başarıyla eklenirse
      console.log('Çalışan başarıyla eklendi:', response.data);
      setSuccessMessage('Çalışan başarıyla eklendi.');
      setErrorMessage('');
      navigate(0);
    } catch (error) {
      console.error('Çalışan eklenemedi:', error.response ? error.response.data : error);
      setErrorMessage('Çalışan eklenemedi. Lütfen bilgileri kontrol edin.');
      setSuccessMessage('');
    }
  };

  // TC Kimlik Numarası için sadece sayı girişine izin verme
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  return (
    <div className="add-user-page">
      <Navbar />
      <div className="form-container">
        <h2>Çalışan Ekle</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Çalışan Adı"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                placeholder="Çalışan Soyadı"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="tc"
                value={formData.tc}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress} // Sadece rakamlara izin veriliyor
                maxLength="11" // Maksimum 11 haneli sınırlama
                placeholder="TC"
                required
              />
            </div>
            <div className="form-group">
              <select
                name="roleId" // Rol ID'si burada atanıyor
                value={formData.roleId}
                onChange={handleInputChange}
                required
              >
                <option value="">Rol Seçin</option>
                {roles.map(role => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Şifre"
                required
              />
              <i
                className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} password-icon`}
                onClick={togglePasswordVisibility}
              ></i>
              {passwordError && <small className="password-error">{passwordError}</small>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Telefon"
                required
              />
            </div>
          </div>

          <button type="submit">Kaydet</button>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
