import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import TrackingPage from './components/TrackingPage/TrackingPage';
import AddCrane from './components/AddCrane/AddCrane';
import AddUser from './components/AddUser/AddUser';
import Profile from './components/Profile/Profile';
import RentalEdit from './components/RentalEdit/RentalEdit';
import AddCompany from './components/AddCompany/AddCompany';
import RentalPage from './components/RentalPage/RentalPage';
import CraneRental from './components/CraneRental/CraneRental';
import UserList from './components/UserList/UserList';
import CompanyList from './components/CompanyList/CompanyList';
import CompanyEdit from './components/CompanyEdit/CompanyEdit';
import UserEdit from './components/UserEdit/UserEdit';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import MyRentals from './components/MyRentals/MyRentals';




function App() {
  return (
    <Router>
      <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/add-crane" element={<AddCrane />} />
        <Route path="/add-company" element={<AddCompany />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/user-list" element={<UserList />} />
        <Route path="/edit-user/:id" element={<UserEdit />} />
        <Route path="/company-list" element={<CompanyList />} />
        <Route path="/edit-company/:id" element={<CompanyEdit />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tracking" element={<TrackingPage />} />
        <Route path="/rentalpage/:id" element={<RentalPage />} />
        <Route path="/rentaledit/:rentalId" element={<RentalEdit />} />
        <Route path="/cranerental" element={<CraneRental />} />
        <Route path="/myrentals" element={<MyRentals />} />
      </Routes>
      </div>
    </Router>
  );
}

export default App;
