import React, { useState } from 'react';  
import Navbar from '../Navbar/Navbar';
import './AddCompany.scss';
import api from '../Axios/axios'; // Axios'u kullanarak backend'e istek yapacağız
import {  useNavigate  } from 'react-router-dom';

const AddCompany = () => {
  const [formData, setFormData] = useState({
    employeeName: '',  // Çalışan adı
    employeeSurname: '',  // Çalışan soyadı
    employeeEmail: '',  // Çalışan e-posta
    employeePassword: '',  // Çalışan şifresi
    hasTowerCrane: false,  // Kule vinç var mı?
    rentTowerCrane: false,  // Kule vinç kiralıyor mu?
    name: '',  // Şirket adı
    email: '',  // Şirket e-postası
    address: '',  // Şirket adresi
    taxNo: '',  // Vergi numarası
    authorizedPerson1: '',  // Yetkili kişi 1
    phoneNumber1: '',  // Yetkili kişi 1 telefon numarası
    authorizedPerson2: '',  // Yetkili kişi 2
    phoneNumber2: '',  // Yetkili kişi 2 telefon numarası
  });
  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Şifre göster/gizle durumu için state
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  // Şifre doğrulama
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    return passwordRegex.test(password);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
        // Şifre doğrulama
        if (name === 'employeePassword') {
          if (!validatePassword(value)) {
            setPasswordError(
              'Şifre en az bir küçük harf, bir büyük harf, bir rakam ve bir özel karakter içermeli ve en az 8 karakter uzunluğunda olmalıdır.'
            );
          } else {
            setPasswordError('');
          }
        }
    // Yalnızca rakamlara izin verilen telefon numarası alanları için kontrol
    if ((name === 'phoneNumber1' || name === 'phoneNumber2') && !/^\d*$/.test(value)) {
      return; // Telefon numarası yalnızca rakamlardan oluşmalı
    }
    
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Şifreyi göster/gizle
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  // Check if the password is valid
  if (!validatePassword(formData.employeePassword)) {
    setErrorMessage('Şifre geçerli değil. En az bir küçük harf, bir büyük harf içermeli ve en az 8 karakter uzunluğunda olmalıdır.');
    return; // Stop submission if the password is invalid
  }
    try {
      const response = await api.post('/Company', formData);  // Verileri backend'e POST ediyoruz
      console.log('Şirket başarıyla eklendi:', response.data);
      setSuccessMessage('Şirket başarıyla eklendi.');
      setErrorMessage('');
      navigate(0);
    } catch (error) {
      console.error('Şirket eklenemedi:', error.response ? error.response.data : error);
      setErrorMessage('Şirket eklenemedi. Lütfen bilgileri kontrol edin.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="add-company-page">
      <Navbar />
      <div className="form-container">
        <h2>Şirket Ekle</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          {/* Çalışan Bilgileri */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="employeeName"
                value={formData.employeeName}
                onChange={handleInputChange}
                placeholder="Çalışan Adı"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="employeeSurname"
                value={formData.employeeSurname}
                onChange={handleInputChange}
                placeholder="Çalışan Soyadı"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="email"
                name="employeeEmail"
                value={formData.employeeEmail}
                onChange={handleInputChange}
                placeholder="Çalışan E-posta"
                required
              />
            </div>
            <div className="form-group password-group">
              <input
                type={showPassword ? 'text' : 'password'}
                name="employeePassword"
                value={formData.employeePassword}
                onChange={handleInputChange}
                placeholder="Çalışan Şifresi"
                required
              />
              <i
                className={`eye-icon ${showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}`}
                onClick={togglePasswordVisibility}
              ></i>
              <p>{passwordError && <p className="error-message">{passwordError}</p>}</p>
            </div>
          </div>

          {/* Kule Vinç Sahibi mi ve Kule Vinç Kiralıyor mu? */}
          <div className="form-row checkbox-group">
            <div className="form-group">
              <label>Kule Vinç Var mı?</label>
              <input
                type="checkbox"
                name="hasTowerCrane"
                checked={formData.hasTowerCrane}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Kule Vinci Kiralıyor mu?</label>
              <input
                type="checkbox"
                name="rentTowerCrane"
                checked={formData.rentTowerCrane}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Şirket Bilgileri */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Şirket Adı"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Şirket E-posta"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Şirket Adresi"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="taxNo"
                value={formData.taxNo}
                onChange={handleInputChange}
                placeholder="Vergi No"
                required
              />
            </div>
          </div>

          {/* Yetkili Kişiler ve İletişim */}
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="authorizedPerson1"
                value={formData.authorizedPerson1}
                onChange={handleInputChange}
                placeholder="Yetkili Kişi 1"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber1"
                value={formData.phoneNumber1}
                onChange={handleInputChange}
                placeholder="Telefon No 1 (Sadece rakamlar)"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="authorizedPerson2"
                value={formData.authorizedPerson2}
                onChange={handleInputChange}
                placeholder="Yetkili Kişi 2"
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber2"
                value={formData.phoneNumber2}
                onChange={handleInputChange}
                placeholder="Telefon No 2 (Sadece rakamlar)"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="bankReceiver1"
                value={formData.bankReceiver1}
                onChange={handleInputChange}
                placeholder="Banka Alıcı Adı 1"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="bankName1"
                value={formData.bankName1}
                onChange={handleInputChange}
                placeholder="Banka Adı 1"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="iban1"
                value={formData.iban1}
                onChange={handleInputChange}
                placeholder="IBAN 1"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="bankReceiver2"
                value={formData.bankReceiver2}
                onChange={handleInputChange}
                placeholder="Banka Alıcı Adı 2"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="bankName2"
                value={formData.bankName2}
                onChange={handleInputChange}
                placeholder="Banka Adı 2"
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="iban2"
                value={formData.iban2}
                onChange={handleInputChange}
                placeholder="IBAN 2"
              />
            </div>
          </div>

          <button type="submit">Kaydet</button>
        </form>
      </div>
    </div>
  );
};

export default AddCompany;
