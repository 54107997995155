import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './CompanyList.scss';
import api from '../Axios/axios'; // Axios'u merkezi yapılandırmadan import ediyoruz

const CompanyList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState([]); // API'den veri çekileceği için başlangıçta boş bir array
  const [showModal, setShowModal] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Şirket verilerini backend'den çekiyoruz
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem('token'); // Token'i localStorage'dan alıyoruz
        const response = await api.get('/Company', {
          headers: {
            Authorization: `Bearer ${token}`, // Token'ı Authorization başlığı ile gönderiyoruz
          },
        });
        setCompanies(response.data.data); // API'den gelen şirket verilerini state'e ekliyoruz
      } catch (error) {
        console.error('Şirketler yüklenemedi:', error);
        setErrorMessage('Şirketler yüklenemedi. Lütfen tekrar deneyin.');
      }
    };

    fetchCompanies(); // Component mount olunca şirketleri çekiyoruz
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteClick = (companyId) => {
    setCompanyToDelete(companyId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await api.delete(`/Company`, {
        data: { id: companyToDelete }, // ID'nin body kısmına eklenmesi
        headers: {
          Authorization: `Bearer ${token}`,
        },
       
      });
      setCompanies(companies.filter(company => company.id !== companyToDelete)); // Şirketi listeden kaldırıyoruz
      setShowModal(false);
    } catch (error) {
      console.error('Şirket silinemedi:', error);
      setErrorMessage('Şirket silinemedi. Lütfen tekrar deneyin.');
    }
  };

  const filteredCompanies = companies.filter(company =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="company-list-page">
      <Navbar />
      <div className="company-list-container">
        <div className="company-list-header">
          <h2>Şirketler</h2>
          <div className="search-and-add">
            <input
              type="text"
              placeholder="Şirket adına göre ara..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-bar"
            />
            <Link to="/add-company" className="add-company-btn">Şirket Ekle</Link>
          </div>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Hata mesajı */}
        <table className="company-list-table">
          <thead>
            <tr>
              <th>Şirket Adı</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredCompanies.length > 0 ? (
              filteredCompanies.map((company) => (
                <tr key={company.id}>
                  <td>{company.name}</td>
                  <td>{company.email}</td>
                  <td>{company.phone ? company.phone : 'Veri bulunmuyor'}</td> {/* Telefon verisi yoksa 'Veri bulunmuyor' yazıyor */}
                  <td className="actions">
                    <Link to={`/edit-company/${company.id}`} className="edit-btn">
                      <i className="fas fa-pencil-alt"></i>
                    </Link>
                    <button className="delete-btn" onClick={() => handleDeleteClick(company.id)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Şirket bulunamadı.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Şirketi silmek istediğinize emin misiniz?</h3>
            <div className="modal-actions">
              <button onClick={confirmDelete} className="confirm-delete-btn">Evet, Sil</button>
              <button onClick={() => setShowModal(false)} className="cancel-btn">Vazgeç</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyList;
