import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Slider from 'react-slick';
import './RentalPage.scss';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../Axios/axios';

const RentalPage = () => {
  const { id: towerCraneId } = useParams();
  const navigate = useNavigate(); // useNavigate replaces useHistory
  const [rentalList, setRentalList] = useState([]);
  const [towerCraneDetails, setTowerCraneDetails] = useState(null);
  const [isExpanded, setIsExpanded] = useState(-1);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    if (towerCraneId) {
      fetchTowerCraneDetails(towerCraneId);
      fetchRentalData(towerCraneId);
    }
  }, [towerCraneId]);
  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    setUserRole(role);
  };

  const fetchTowerCraneDetails = async (id) => {
    try {
      const response = await axios.get(`/TowerCrane/GetById?id=${id}`);
      const craneData = response.data.data;

      if (craneData) {
        setTowerCraneDetails({
          serialNumber: craneData.serialNo || 'Bilinmiyor',
          owner: craneData.owner || 'Bilinmiyor',
          brand: craneData.brand || 'Bilinmiyor',
          craneCode: craneData.model || 'Bilinmiyor',
          height: craneData.height || 'Bilinmiyor',
          maxTonnage: craneData.maxTonnage || 'Bilinmiyor',
          productionYear: craneData.productionYear || 'Bilinmiyor',
          status: craneData.status === 'InUsed' ? 'Kirada' : craneData.status === 'Active' ? 'Depoda' : 'Bilinmiyor',
        });
      }
    } catch (error) {
      console.error('Kule vinç bilgileri alınamadı:', error);
    }
  };

  const fetchRentalData = async (id) => {
    try {
      const response = await axios.get(`/Rental/GetByTowerCrane/${id}`);
      const rentalDataArray = response.data.data;
      console.log(rentalDataArray);
      if (rentalDataArray && rentalDataArray.length > 0) {
        setRentalList(rentalDataArray);
        const totalRevenue = calculateTotalRevenue(rentalDataArray);
        fetchCompanyAndSiteDetails(rentalDataArray);
        setTotalRevenue(totalRevenue);

      } else {
        setRentalList([]);
      }
    } catch (error) {
      console.error('Kiralama verileri alınamadı:', error);
    }
  };
  const formatCurrency = (amount) => {
    // Sayıyı biçimlendir ve TL birimini sağa ekle
    return new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount) + ' TL';
  };
  const calculateTotalRevenue = (rentalDataArray) => {
    // Tüm kiralama verilerini alarak gelir hesaplıyoruz
    const totalRevenue = rentalDataArray.reduce((acc, rental) => {
      // Kiralama süresi boyunca elde edilen gelir
      const baseRevenue = (rental.rent || 0) * (rental.rentalPeriod || 0);
      // Uzatma süresi boyunca elde edilen gelir (varsa)
      const extraRevenue = (rental.rent || 0) * (rental.extraPeriod || 0);
      // Toplam gelire ekle
      return acc + baseRevenue + extraRevenue;
    }, 0);
    console.log(totalRevenue);
    const formattedRevenue = formatCurrency(totalRevenue);

    return formattedRevenue;
  };
  
  const fetchCompanyAndSiteDetails = async (rentalDataArray) => {
    try {
      const updatedRentals = await Promise.all(
        rentalDataArray.map(async (rental) => {
          let companyName = 'Bilinmiyor';
          let siteAddress = 'Bilinmiyor';

          if (rental.rentingCompanyId) {
            const companyResponse = await axios.get(`/Company/GetById?id=${rental.rentingCompanyId}`);
            companyName = companyResponse.data.data?.name || 'Bilinmiyor';
          }

          if (rental.workSiteId) {
            const siteResponse = await axios.get(`/Worksite/GetById?id=${rental.workSiteId}`);
            siteAddress = siteResponse.data.data?.address || 'Bilinmiyor';
          }

          return { ...rental, companyName, siteAddress };
        })
      );
      setRentalList(updatedRentals);
    } catch (error) {
      console.error('Şirket veya şantiye bilgileri alınamadı:', error);
    }
  };

  const toggleRentalSection = (index) => {
    setIsExpanded((prevExpanded) => (prevExpanded === index ? -1 : index));
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  };

  const navigateToEditPage = (rentalId) => {
    navigate(`/rentaledit/${rentalId}`);
  };

  return (
    <div className="rental-page">
      <Navbar />
      <h2>Kiralama Bilgileri</h2>

      {towerCraneDetails ? (
        <div className="crane-info">
          <h3>Kule Vinç Bilgileri</h3>
          <div className="crane-details">
            <div><strong>Seri No:</strong> {towerCraneDetails.serialNumber}</div>
            <div><strong>Sahibi:</strong> {towerCraneDetails.owner}</div>
            <div><strong>Marka:</strong> {towerCraneDetails.brand}</div>
            <div><strong>Vinç Kodu:</strong> {towerCraneDetails.craneCode}</div>
            <div><strong>Yükseklik:</strong> {towerCraneDetails.height}m</div>
            <div><strong>Maks. Tonaj:</strong> {towerCraneDetails.maxTonnage}</div>
            <div><strong>Durum:</strong> {towerCraneDetails.status}</div>
          </div>
          <div className="crane-details">
          <div  className="totalrevenue"><strong>Toplam Gelir:</strong> {totalRevenue}</div>

          </div>
        </div>
      ) : (
        <p>Kule vinç bilgisi bulunamadı.</p>
      )}

      {rentalList.length > 0 && rentalList.slice().reverse().map((rental, index) => (
        <div key={index} className="rental-section">
          <div className="rental-header" onClick={() => toggleRentalSection(index)}>
            <h4>{rentalList.length - index}. Kiralama - {rental.companyName}</h4>
            <p>{isExpanded === index ? 'Kapat' : 'Aç'}</p>
          </div>

          {isExpanded === index && (
            <div className="rental-details">
              {(userRole === 'owner' || rental.isActive )&& (
                <button className="edit-btn" onClick={() => navigateToEditPage(rental.id)}>Düzenle</button>
              )}
              <h4>Şantiye Bilgileri</h4>
              <div className="info-row">
                <p><strong>Şantiye Adı:</strong> {rental.workSite || 'Bilinmiyor'}</p>
                <p><strong>Şantiye Adresi:</strong> {rental.siteAddress}</p>
              </div>

              <h4>Kiralama Bilgileri</h4>
              <div className="info-row">
                <p><strong>Kira Başlangıç Tarihi:</strong> {rental.rentalStartDate || 'Bilinmiyor'}</p>
                <p><strong>Kira Süresi:</strong>    {rental.rentalPeriod ? `${rental.rentalPeriod} Ay İlk Sözleşme` : 'Bilinmiyor'}
                {rental.extraPeriod ? ` + ${rental.extraPeriod} Ay Uzatma Süresi` : ''}</p>
                <p><strong>Kira Bitiş Tarihi:</strong> {rental.rentalEndDate || 'Bilinmiyor'}</p>
                <p><strong>Kira Bedeli (KDV Dahil):</strong> {rental.rent ? formatCurrency(rental.rent) : 'Bilinmiyor'}</p>
              </div>

              <h4>Belgeler</h4>
              <div className="documents-row">
                {rental.files && rental.files.map((file, fileIndex) => {
                  const fileLabel = {
                    Offer: 'Teklif Dosyası',
                    Contract: 'Sözleşme Dosyası',
                    ServiceForm: 'Servis Formu',
                    Image: 'Görsel'
                  }[file.fileType] || 'Diğer';

                  return (
                    <a
                      key={fileIndex}
                      href={`https://api.kulevinctakip.com/uploads/${file.fileName}`} // Updated URL
                      target="_blank"
                      rel="noopener noreferrer"
                      className="document"
                    >
                      {fileLabel}
                    </a>
                  );
                })}
              </div>

              <h4>Notlar</h4>
              <div className="notes-container">
                {Array.isArray(rental.notes) && rental.notes.length > 0 ? (
                  rental.notes.map((noteObj, noteIndex) => (
                    <div key={noteIndex} className="note-item">
                      {noteObj.note}
                    </div>
                  ))
                ) : (
                  <p>Not bulunamadı.</p>
                )}
              </div>

              <h4>Hizmet Bedeli Oranı</h4>
              <p>{rental.commissionRate || 'Bilinmiyor'}%</p>

              <h4>Görseller</h4>
              <Slider {...sliderSettings} className="slider-image-container">
                {rental.files
                  .filter((file) => file.fileType === 'Image')
                  .map((imageFile, imageIndex) => (
                    <div key={imageIndex} className="slider-image">
                      <img           src={`https://api.kulevinctakip.com/uploads/${imageFile.fileName}`} // Updated URL
          alt={`Rental Image ${imageIndex + 1}`} />
                    </div>
                  ))}
              </Slider>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RentalPage;
