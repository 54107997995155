import React, { useState, useEffect } from 'react';   
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './UserEdit.scss';
import api from '../Axios/axios';  // Axios'u ortak bir dosyadan import edin

const UserEdit = () => {
  const { id } = useParams();  // URL'deki kullanıcı id'sini alıyoruz
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    roleId: '', // Kullanıcının mevcut rolü ID olarak gelecek
    email: '',
    phoneNumber: '',
    tc: '',
    password: '',
    id: ''
  });

  const [roles, setRoles] = useState([]); // Rolleri tutmak için state
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Kullanıcı bilgileri ve rolleri sayfa yüklendiğinde çekiyoruz
  useEffect(() => {
    const fetchUserAndRoles = async () => {
      try {
        const token = localStorage.getItem('token');  // Token'ı localStorage'dan alıyoruz

        // Kullanıcı bilgilerini çekiyoruz
        const userResponse = await api.get(`/User/GetByUserId?userId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Token'ı Authorization başlığı ile gönderiyoruz
          },
        });

        const userData = userResponse.data.data;
        console.log('Kullanıcı verisi:', userData);

        // Rolleri API'den çekiyoruz
        const rolesResponse = await api.get('/Auth/GetAllRoles', {
          headers: {
            Authorization: `Bearer ${token}`, // Token'ı Authorization başlığı ile gönderiyoruz
          },
        });

        const fetchedRoles = rolesResponse.data.data;
        
        setRoles(fetchedRoles); // Rolleri state'e ekliyoruz

        // Kullanıcı bilgilerini formData'ya yerleştiriyoruz
        setFormData({
          name: userData.name,
          surname: userData.surname,
          roleId: userData.roleId || fetchedRoles.find(role => role.name === userData.role)?.id, // Kullanıcının mevcut rolü, // Kullanıcının mevcut rolü varsa roleId'yi al, yoksa boş bırak
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          tc: userData.tc,
          id: userData.id,
          password: ''  // Şifreyi boş tutuyoruz, şifre değiştirilmek istenirse doldurulabilir
        });

        setLoading(false);
      } catch (error) {
        console.error('Veriler yüklenemedi:', error);
        setErrorMessage('Kullanıcı bilgileri veya roller yüklenemedi. Lütfen tekrar deneyin.');
        setLoading(false);
      }
    };

    fetchUserAndRoles();
  }, [id]);

  // Girdi değişikliği kontrolü
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // TC için sadece 11 haneli sayı kontrolü
    if (name === 'tc' && value.length > 11) {
      return; // TC 11 haneli olmalı
    }

    // Telefon numarası için sadece rakamlar
    if (name === 'phoneNumber' && !/^\d*$/.test(value)) {
      return; // Yalnızca rakamlara izin verilir
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Form submit işlemi
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.tc.length !== 11) {
      setErrorMessage('TC Kimlik Numarası 11 haneli olmalıdır.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      console.log("Gönderilen FormData:", formData);

      // API isteğinde userId'yi de açıkça belirtiyoruz
      await api.put(
        `/User/`,  // Belirli bir userId ile çağrı yapıyoruz
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Token'ı Authorization başlığı ile gönderiyoruz
          },
        }
      );

      setSuccessMessage('Kullanıcı başarıyla güncellendi.');
      setErrorMessage('');
      navigate('/user-list');
    } catch (error) {
      console.error('Kullanıcı güncellenemedi:', error);
      setErrorMessage('Kullanıcı güncellenemedi. Lütfen bilgileri kontrol edin.');
      setSuccessMessage('');
    }
  };


  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  return (
    <div className="user-edit-page">
      <Navbar />
      <div className="form-wrapper">
        <h2>Kullanıcı Düzenle</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Ad"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                placeholder="Soyad"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Telefon (Yalnızca rakamlar)"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                disabled  // Email değiştirilemez
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="tc"
                value={formData.tc}
                onChange={handleInputChange}
                placeholder="TC Kimlik No"
                required
              />
            </div>
            <div className="form-group">
              <select
                name="roleId"
                value={formData.roleId || ''}  // Eğer roleId yoksa boş bırak
                onChange={handleInputChange}
                required
              >
                <option value="">Rol Seçin</option>
                {roles.map(role => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button type="submit">Güncelle</button>
        </form>
      </div>
    </div>
  );
};

export default UserEdit;
