import React, { useState } from 'react';  
import './Login.scss';
import api from '../Axios/axios'; // axios'u merkezi yapıdan çekiyoruz
import logo1 from '../../assets/ttcLogo.png';
import logo2 from '../../assets/starcraneLogo.png';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [loginError, setLoginError] = useState(null); // Hata mesajı için state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await api.post('/Auth/Login', { email, password });
  
      if (response.status === 200 && response.data.data?.token) {
        // Token'ı kaydet ve yönlendir
        localStorage.setItem('token', response.data.data?.token);
        console.log('Giriş başarılı:', response.data);
        setLoginError(null); // Hata mesajını sıfırla
        navigate('/tracking');
      } else {
        setLoginError('Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      if (error.response) {
        // Backend hatasını al
        console.error('API Hatası:', error.response.data);
        setLoginError(error.response.data.message || 'Giriş başarısız. Bilgilerinizi kontrol edin.');
      } else if (error.request) {
        // İstek atıldı ancak yanıt alınamadı
        console.error('Sunucudan yanıt alınamadı:', error.request);
        setLoginError('Sunucudan yanıt alınamadı. Lütfen tekrar deneyin.');
      } else {
        // Diğer hatalar
        console.error('Bir hata oluştu:', error.message);
        setLoginError('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };
  

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    console.log('Forgot Email:', forgotEmail);
    // Şifremi unuttum için istek yapılacak (backend endpoint eklenmeli)
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="app-container">
      <div className="login-container">
        <h1>Kule Vinç Takip Sistemi</h1>
        <div className="logos">
          <img src={logo1} alt="TTC Logo" />
          <img src={logo2} alt="StarCrane Logo" />
        </div>
        <div className="login-form-container">
          <h2>Giriş Yap</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
              required 
            />
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Şifre" 
              required 
            />
            <button type="submit">Giriş Yap</button>
            {loginError && <p className="error-message">{loginError}</p>} {/* Hata mesajı */}
            <a href="#" className="forgot-password" onClick={() => setIsModalOpen(true)}>
              Şifremi Unuttum?
            </a>
          </form>
        </div>

        {/* Şifremi Unuttum Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <span className="close-modal" onClick={closeModal}>&times;</span>
              <h3>Şifre Sıfırlama</h3>
              <form onSubmit={handleForgotPasswordSubmit}>
                <input 
                  type="email" 
                  value={forgotEmail} 
                  onChange={(e) => setForgotEmail(e.target.value)} 
                  placeholder="Email adresinizi girin" 
                  required 
                />
                <button type="submit">Gönder</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
