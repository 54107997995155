import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './Profile.scss';
import api from '../Axios/axios'; // Axios ile API bağlantısı kuruyoruz

const Profile = () => {
  const { id } = useParams(); // URL'den ID alıyoruz
  const navigate = useNavigate(); // Sayfa yönlendirmeleri için

  const [userType, setUserType] = useState(''); // Kullanıcı tipi: employee veya company
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [roles, setRoles] = useState([]); // Rolleri tutmak için state

  const getUserFromToken = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Token çözümleme hatası:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const decodedToken = getUserFromToken(token);
        const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        const userId = decodedToken?.['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        const companyId = decodedToken?.CompanyId;

        setUserType(role);

        if (role === 'Company') {
          const companyResponse = await api.get(`/Company/GetById?id=${companyId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFormData(companyResponse.data.data || {});
        } else if (role === 'Employee' || role === 'Owner') {
          const userResponse = await api.get(`/User/GetByUserId?userId=${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFormData(userResponse.data.data || {});

          // Rolleri çek
          const rolesResponse = await api.get('/Auth/GetAllRoles', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setRoles(rolesResponse.data.data);
        }

        setErrorMessage('');
        setLoading(false);
      } catch (error) {
        console.error('Veri yükleme hatası:', error);
        setErrorMessage('Veriler yüklenemedi. Lütfen tekrar deneyin.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');

      if (userType === 'Company') {
        await api.put('/Company', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        await api.put('/User', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      setSuccessMessage('Bilgiler başarıyla güncellendi.');
      setErrorMessage('');
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      setErrorMessage('Güncelleme sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      setSuccessMessage('');
    }
  };

  if (loading) return <p>Yükleniyor...</p>;

  return (
    <div className="profile-edit-page">
      <Navbar />
      <div className="outer-container">
      <div className="inner-container">
      <div className="form-wrapper">
        <h2>{userType === 'Company' ? 'Şirket Düzenle' : 'Kullanıcı Düzenle'}</h2>
        <form onSubmit={handleSubmit}>
          {userType === 'Company' ? (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Şirket Adı</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Şirket Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="address">Şirket Adresi</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    value={formData.address || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="taxNo">Vergi No</label>
                  <input
                    type="text"
                    name="taxNo"
                    id="taxNo"
                    value={formData.taxNo || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row checkbox-group">
                <div className="form-group">
                  <label>Kule Vinci Var mı?</label>
                  <input
                    type="checkbox"
                    name="hasTowerCrane"
                    checked={formData.hasTowerCrane || false}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Kule Vinci Kiralıyor mu?</label>
                  <input
                    type="checkbox"
                    name="rentTowerCrane"
                    checked={formData.rentTowerCrane || false}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="authorizedPerson1">Yetkili Kişi 1</label>
                  <input
                    type="text"
                    name="authorizedPerson1"
                    id="authorizedPerson1"
                    value={formData.authorizedPerson1 || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber1">İletişim No 1</label>
                  <input
                    type="tel"
                    name="phoneNumber1"
                    id="phoneNumber1"
                    value={formData.phoneNumber1 || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="authorizedPerson2">Yetkili Kişi 2</label>
                  <input
                    type="text"
                    name="authorizedPerson2"
                    id="authorizedPerson2"
                    value={formData.authorizedPerson2 || ''}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber2">İletişim No 2</label>
                  <input
                    type="tel"
                    name="phoneNumber2"
                    id="phoneNumber2"
                    value={formData.phoneNumber2 || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="name">Ad</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                      <label htmlFor="surname">Soyad</label>
                      <input
                        type="text"
                        name="surname"
                        id="surname"
                        value={formData.surname || ''}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="phoneNumber">Telefon Numarası</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email || ''}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="form-row">

                <div className="form-group">
                      <label htmlFor="tc">TC Kimlik No</label>
                      <input
                        type="text"
                        name="tc"
                        id="tc"
                        value={formData.tc || ''}
                        onChange={handleInputChange}
                        required
                      />
                  </div>
                <div className="form-group">
                </div>
                  
              </div>
            </>
          )}

          <button type="submit">Kaydet</button>
        </form>


        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
