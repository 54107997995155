import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import './AddCrane.scss';
import api from '../Axios/axios'; // Axios'u backend bağlantısı için kullanıyoruz
import {  useNavigate  } from 'react-router-dom';

const AddCrane = () => {
  const [formData, setFormData] = useState({
    serialNo: '',
    ownerId: '',
    brandId: '',
    code: '',
    height: '',
    maxTonnage: '',
    creationDate: new Date().toISOString(),
    type: 'Chassis',
    hasConnectionEquipment: false,
    connectionEquipmentCount: 0,
    isInWarehouse: false,
    warehouseId: '',
    hasWalkingGear: false,
    depotAddress: ''
  });
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]); // API'den gelecek markalar
  const [craneOwners, setCraneOwners] = useState([]); // Kule vinç sahipleri
  const [depots, setDepots] = useState([]); // Depolar
  const [newBrand, setNewBrand] = useState('');
  const [newDepotName, setNewDepotName] = useState('');
  const [newDepotAddress, setNewDepotAddress] = useState('');

  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isDepotModalOpen, setIsDepotModalOpen] = useState(false);

  // API çağrıları sayfa yüklendiğinde yapılacak
  useEffect(() => {
    fetchBrands();
    fetchDepots();
    fetchCraneOwners();
  }, []);

  // Markaları GET ile çekiyoruz
  const fetchBrands = async () => {
    try {
      const response = await api.get('/Brand');
      setBrands(response.data.data); // Markalar set ediliyor
    } catch (error) {
      console.error('Markalar yüklenemedi:', error);
    }
  };

  // Depoları GET ile çekiyoruz
  const fetchDepots = async () => {
    try {
      const response = await api.get('/Warehouse');
      setDepots(response.data.data); // Depolar set ediliyor
    } catch (error) {
      console.error('Depolar yüklenemedi:', error);
    }
  };

  // Kule vinç sahiplerini GET ile çekiyoruz
  const fetchCraneOwners = async () => {
    try {
      const response = await api.get('/Company');
      const craneOwners = response.data.data.filter(company => company.hasTowerCrane); // Sadece hasTowerCrane true olanları filtrele
      setCraneOwners(craneOwners);
      console.log("Kule vinç sahipleri:", craneOwners); // Kule vinç sahiplerini console.log ile gösteriyoruz
    } catch (error) {
      console.error('Kule Vinç Sahipleri yüklenemedi:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleDepotChange = (e) => {
    const selectedDepot = depots.find((depot) => depot.id === parseInt(e.target.value));
    setFormData({
      ...formData,
      warehouseId: selectedDepot ? selectedDepot.id : '',
      depotAddress: selectedDepot?.address || '' // Depo adresini set ediyoruz
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await api.post('/TowerCrane', formData, {
        headers: {
          Authorization: `Bearer ${token}` // Yetkilendirme için token ekliyoruz
        }
      });
      console.log('Veri', formData);
      console.log('Kule vinç başarıyla eklendi:', response.data);
      navigate(0);
    } catch (error) {
      console.error('Kule vinç eklenemedi:', error.response ? error.response.data : error);
    }
  };

  // Yeni marka eklemek
  const handleAddBrand = async () => {
    if (newBrand) {
      try {
        const response = await api.post('/Brand', { name: newBrand });
        const addedBrand = response.data?.data || {}; // API yanıtını kontrol ediyoruz
        if (addedBrand.id) {
          setBrands([...brands, addedBrand]); // Yeni eklenen markayı listeye ekle
          setNewBrand('');
          setIsBrandModalOpen(false);
        } else {
          console.error('Marka eklenirken id gelmedi:', addedBrand);
          window.location.reload(); // "Ekle" butonuna tıkladıktan sonra sayfayı yenile

        }
      } catch (error) {
        console.error('Marka eklenemedi:', error);
      }
    }
  };

  // Yeni depo eklemek
  const handleAddDepot = async () => {
    if (newDepotName && newDepotAddress) {
      try {
        const response = await api.post('/Warehouse', { name: newDepotName, address: newDepotAddress });
        const addedDepot = response.data?.data || {}; // API yanıtını kontrol ediyoruz
        if (addedDepot.id) {
          setDepots([...depots, addedDepot]); // Yeni eklenen depoyu listeye ekle
          setNewDepotName('');
          setNewDepotAddress('');
          setIsDepotModalOpen(false);
        } else {
          console.error('Depo eklenirken id gelmedi:', addedDepot);
          window.location.reload(); // "Ekle" butonuna tıkladıktan sonra sayfayı yenile
        }
      } catch (error) {
        console.error('Depo eklenemedi:', error);
      }
    }
  };

  return (
    <div className="add-crane-page">
      <Navbar />
      <div className="form-container">
        <h2>Kule Vinç Ekle</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="serialNo"
                value={formData.serialNo}
                onChange={handleInputChange}
                placeholder="Seri No"
                required
              />
            </div>
            <div className="form-group">
            <select
              name="ownerId"
              value={formData.ownerId}
              onChange={handleInputChange}
              required
            >
              <option value="">Kule Vinç Sahibi Seçin</option>
              {craneOwners.map((owner) => (
                <option key={owner.id} value={owner.id}>
                  {owner.name}
                </option>
              ))}
            </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <select
                name="brandId"
                value={formData.brandId}
                onChange={handleInputChange}
                required
              >
                <option value="">Marka Seçin</option>
                {brands.length > 0 ? (
                  brands.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))
                ) : (
                  <option value="">Markalar Yükleniyor...</option>
                )}
              </select>
              <small className="add-link" onClick={() => setIsBrandModalOpen(true)}>+ Marka Ekle</small>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
                placeholder="Vinç Kodu"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <input
                type="number"
                name="height"
                value={formData.height}
                onChange={handleInputChange}
                placeholder="Yükseklik (m)"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                name="maxTonnage"
                value={formData.maxTonnage}
                onChange={handleInputChange}
                placeholder="Max Tonaj (T)"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                required
              >
                <option value="Chassis">Şaseli</option>
                <option value="Anchored">Ankarajlı</option>
              </select>
            </div>
            <div className="form-group checkbox-group">
              <label>
                Bağlantı Ekipmanı Var mı?
                <input
                  type="checkbox"
                  name="hasConnectionEquipment"
                  checked={formData.hasConnectionEquipment}
                  onChange={handleInputChange}
                  className='checkbox'
                />
              </label>
              {formData.hasConnectionEquipment && (
                <input
                  type="number"
                  name="connectionEquipmentCount"
                  value={formData.connectionEquipmentCount}
                  onChange={handleInputChange}
                  placeholder="Kaç Adet?"
                  required
                />
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>
                Depoda mı?
                <input
                  type="checkbox"
                  name="isInWarehouse"
                  checked={formData.isInWarehouse}
                  onChange={handleInputChange}
                  className='checkbox'
                />
              </label>
            </div>
            <div className='form-group'>
            <label>
              Yürüyüş Takımı Var mı?
              <input
                type="checkbox"
                name="hasWalkingGear"
                checked={formData.hasWalkingGear}
                onChange={handleInputChange}
                className='checkbox'
              />
            </label>
            </div>
            

          </div>

          <div className="form-row">
            <div className="form-group">
              <select
                name="warehouseId"
                value={formData.warehouseId}
                onChange={handleDepotChange}
                disabled={!formData.isInWarehouse}
                required={formData.isInWarehouse}
              >
                <option value="">Depo Seçin</option>
                {depots.length > 0 ? (
                  depots.map((depot) => (
                    <option key={depot.id} value={depot.id}>
                      {depot.name}
                    </option>
                  ))
                ) : (
                  <option value="">Depolar Yükleniyor...</option>
                )}
              </select>
              <small className="add-link" onClick={() => setIsDepotModalOpen(true)}>+ Depo Ekle</small>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="depotAddress"
                value={formData.depotAddress}
                placeholder="Depo Adresi"
                readOnly
              />
            </div>
          </div>

          <div className="form-row">
            <button type="submit">Kaydet</button>
          </div>
        </form>
      </div>

      {/* Modal for adding a new brand */}
      {isBrandModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Yeni Marka Ekle</h3>
            <input
              type="text"
              value={newBrand}
              onChange={(e) => setNewBrand(e.target.value)}
              placeholder="Yeni Marka Adı"
              required
            />
            <div className="modal-buttons">
              <button onClick={handleAddBrand}>Ekle</button>
              <button onClick={() => setIsBrandModalOpen(false)}>İptal</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for adding a new depot */}
      {isDepotModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Yeni Depo Ekle</h3>
            <input
              type="text"
              value={newDepotName}
              onChange={(e) => setNewDepotName(e.target.value)}
              placeholder="Depo Adı"
              required
            />
            <input
              type="text"
              value={newDepotAddress}
              onChange={(e) => setNewDepotAddress(e.target.value)}
              placeholder="Depo Adresi"
              required
            />
            <div className="modal-buttons">
              <button onClick={handleAddDepot}>Ekle</button>
              <button onClick={() => setIsDepotModalOpen(false)}>İptal</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCrane;
